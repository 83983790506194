import React, { lazy, Suspense, useState } from 'react';
import UserProfileSidebar from '../components/UserProfileSidebar';
import uspsb from '../styles/UserProfilePage.module.css'; // 引入樣式
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons"; // 引入漢堡圖標
import { Link } from 'react-router-dom';

// 使用 lazy 動態載入各個頁面
const UserProfile = lazy(() => import('../components/UserProfile'));
const UserInfo = lazy(() => import('../components/UserInfo'));
const ChangePassword = lazy(() => import('../components/ChangePassword'));

const UserProfilePage = () => {
  const [selectedPage, setSelectedPage] = useState('profile'); // 預設顯示 "個人檔案"
  const [sidebarVisible] = useState(true); // 控制側邊欄顯示/隱藏
  const [menuVisible, setMenuVisible] = useState(false); // 控制下拉選單顯示

  // 切換漢堡菜單顯示狀態
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  // 根據 selectedPage 來動態顯示相應的頁面
  const renderSelectedPage = () => {
    switch (selectedPage) {
      case 'profile':
        return <UserProfile />;
      case 'info':
        return <UserInfo />;
      case 'password':
        return <ChangePassword />;
      default:
        return <UserProfile />; // 預設顯示 "個人檔案"
    }
  };

  return (
    <div>
      <div className={uspsb.headerContainer} id="userProfileHeader">
        <div className={uspsb.titlecontainer}>
          <Link to="/home" className={uspsb.title}>
            <div className={uspsb.title}>
              ⬅返回上一頁
            </div>
          </Link>
        </div>

        {/* 漢堡圖標，用於切換側邊欄顯示 */}
        <div className={uspsb['header-icon']}>
          <FontAwesomeIcon
            icon={faBars}
            size="2x"
            onClick={toggleMenu} // 點擊後顯示或隱藏下拉選單
            style={{ cursor: 'pointer' }} // 讓滑鼠指針變成手型，表示可點擊
            className={uspsb.hamburgerIcon}
          />
          {/* 漢堡菜單的下拉選單 */}
          {menuVisible && (
            <div className={uspsb.menu}>
              <ul className={uspsb.menuList}>
                <li
                  className={uspsb.menuItem}
                  onClick={() => { setSelectedPage('profile'); setMenuVisible(false); }}>
                  個人檔案
                </li>
                <li
                  className={uspsb.menuItem}
                  onClick={() => { setSelectedPage('info'); setMenuVisible(false); }}>
                  會員資料
                </li>
                <li
                  className={uspsb.menuItem}
                  onClick={() => { setSelectedPage('password'); setMenuVisible(false); }}>
                  更改密碼
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className={uspsb['page-container']}>
        {/* 根據 sidebarVisible 來決定是否顯示側邊欄 */}
        {sidebarVisible && (
          <UserProfileSidebar setSelectedPage={setSelectedPage} />
        )}
        <div className={uspsb['main-content']}>
          <Suspense fallback={<div>Loading...</div>}>
            {renderSelectedPage()}
          </Suspense>
        </div>
      </div>
    </div >
  );
};

export default UserProfilePage;
