import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import PrivateRoute from './components/PrivateRoute'; // PrivateRoute for protected routes
import LoginPage from './pages/LoginPage'; // Login page
import HomePage from './pages/HomePage'; // Home page
import RightSidebarPage from './pages/RightSidebarPage'; // Right sidebar page
import UserProfilePage from './pages/UserProfilePage'; // User profile page
import AssistantPage from './pages/AssistantPage'; // Assistant page
import ResetPassword from './components/ResetPassword'; // Password reset page
import ImageCropModal from './components/ImageCropModal'; // Image crop modal
import NutritionPage from './pages/NutritionPage'; // Nutrition page
import AdminPage from './pages/AdminPage'; // Admin page
import AdminRoute from './components/AdminRoute'; // Admin route for protected admin pages
import OfficialPage from './pages/OfficialPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route
                    path="/home"
                    element={<PrivateRoute element={<HomePage />} />}
                />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route
                    path="/userprofile"
                    element={<PrivateRoute element={<UserProfilePage />} />}
                />
                <Route
                    path="/eatdata"
                    element={<PrivateRoute element={<RightSidebarPage />} />}
                />{' '}
                {/* Right sidebar page */}
                <Route
                    path="/assistant"
                    element={<PrivateRoute element={<AssistantPage />} />}
                />{' '}
                {/* Assistant page */}
                <Route
                    path="/image"
                    element={<PrivateRoute element={<ImageCropModal />} />}
                />
                <Route
                    path="/nutrition"
                    element={<PrivateRoute element={<NutritionPage />} />}
                />
                <Route
                    path="/admin"
                    element={<AdminRoute element={<AdminPage />} />}
                />{' '}
                {/* Admin protected route */}
                <Route path="/logout" element={<LoginPage />} />{' '}
                {/* Logout redirects to login */}
                <Route path="/fitfeed" element={<OfficialPage />} />{' '}
                {/* Logout redirects to login */}
            </Routes>
        </Router>
    </GoogleOAuthProvider>,
);

reportWebVitals();
