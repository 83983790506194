import React, { useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import 'moment/locale/zh-tw';
import momentTimezone from 'moment-timezone';
import postcontent from '../styles/PostContent.module.css';
import PostSuccessModal from './PostSuccessModal';
import EditPostModal from './EditPostModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import ConfirmDeleteSuccessModal from './ConfirmDeleteSuccessModal';
import CommentSection from './CommentSection';
import defaultProfilePic from '../assets/defaultProfilePic.png';
import LikeButton from './LikeButton';
import UserProfileModal from './UserProfileModal';

// 引入拆分後的處理函數
import { handlePostSubmit, handleImageChange } from './PostSubmit';
import { handleConfirmDelete } from './PostDelete';
import { handleSaveEditedPost } from './PostUpdate';
import { handleCommentSubmit } from './CommentSubmit';

// 解析 Token 中的 userid
const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.userid || null; // 假設 token 中的 userid 為 "userid"
        } catch (error) {
            console.error('Token 解析失敗:', error);
            return null;
        }
    }
    return null;
};

const PostContent = () => {
    const [posts, setPosts] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [postContent, setPostContent] = useState('');
    const [postImage, setPostImage] = useState(null);
    const [visibility, setVisibility] = useState('公開');
    const [successMessage, setSuccessMessage] = useState(null);
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
    const currentUserId = getUserIdFromToken();
    const [editingPost, setEditingPost] = useState(null);
    const [isConfirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);
    const [comments, setComments] = useState({});
    const [commentInput, setCommentInput] = useState({});
    const [profilePics, setProfilePics] = useState({});
    const [reset, setReset] = useState(false);
    const [hasLiked, setHasLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [currentUsername, setCurrentUsername] = useState('');
    const [currentProfilePic, setCurrentProfilePic] = useState('');
    const [page, setPage] = useState(0);          // 當前頁數
    const [loading, setLoading] = useState(false); // 是否正在加載
    const [hasMore, setHasMore] = useState(true);  // 是否有更多文章

    useEffect(() => {
        const fetchCurrentUserProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) throw new Error('未找到 Token');

                const response = await fetch(
                    `https://tongbro.ddns.net:8443/api/v1/user/profile/${currentUserId}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    },
                );

                if (!response.ok) {
                    throw new Error('獲取當前用戶資料失敗');
                }

                const userData = await response.json();
                setCurrentUsername(userData.username);
                setCurrentProfilePic(userData.profilePic);
            } catch (error) {
                console.error('獲取當前用戶資料失敗:', error);
                alert(`發生錯誤: ${error.message}`);
            }
        };

        fetchCurrentUserProfile();
    }, [currentUserId]);

    useEffect(() => {
    }, [hasLiked, likes]);

    const handleLikeChange = (postId, newLikes) => {
        setPosts((prevPosts) =>
            prevPosts.map((post) =>
                post.id === postId ? { ...post, likes: newLikes } : post,
            ),
        );
    };

    const handleUserClick = (userid) => {
        setSelectedUserId(userid); // 點擊用戶時打開彈窗
    };

    const onCloseUserModal = () => {
        setSelectedUserId(null); // 關閉彈窗
    };

    // 調用拆分後的 handlePostSubmit 函數
    const onHandlePostSubmit = () => {
        handlePostSubmit({
            postContent,
            setPostContent,
            postImage,
            visibility,
            currentUserId,
            setIsExpanded,
            setPostImage,
            setPosts,
            setSuccessMessage,
            setReset,
            setHasLiked,
            setLikes,
            currentProfilePic,
            currentUsername,
        });
    };

    // 調用拆分後的 handleImageChange 函數
    const onHandleImageChange = (e) => {
        handleImageChange(e, setPostImage);
    };

    // 調用拆分後的 handleConfirmDelete 函數
    const onHandleConfirmDelete = () => {
        handleConfirmDelete({
            postToDelete,
            setPosts,
            setDeleteSuccessMessage,
            setReset,
            setConfirmDeleteVisible,
            setPostToDelete,
        });
    };

    // 調用拆分後的 handleSaveEditedPost 函數
    const onHandleSaveEditedPost = (updatedPost) => {
        handleSaveEditedPost({
            updatedPost,
            setPosts,
            setEditingPost,
        });
    };

    // 調用拆分後的 handleCommentSubmit 函數
    const onHandleCommentSubmit = (postId) => {
        handleCommentSubmit({
            postId,
            commentInput,
            currentUserId,
            setCommentInput,
            fetchComments,
        });
    };

    // 獲取留言
    const fetchComments = async (postId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('未找到 Token，請重新登入');

            const response = await fetch(
                `https://tongbro.ddns.net:8443/api/v1/user/messages/post/${postId}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );

            if (!response.ok) {
                if (response.status === 404) {
                    setComments((prev) => ({
                        ...prev,
                        [postId]: [],
                    }));
                    return;
                }
                const errorText = await response.text();
                throw new Error(`獲取留言失敗: ${errorText}`);
            }

            const data = await response.json();
            setComments((prev) => ({
                ...prev,
                [postId]: data,
            }));
        } catch (error) {
            console.error('Failed to fetch comments:', error);
            alert(`獲取留言失敗: ${error.message}`);
            if (error.message.includes('Token')) {
                window.location.href = '/';
            }
        }
    };

    // 滾動事件的回調函數
    const handleScroll = useCallback(() => {
        if (loading || !hasMore) return;
        if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100) {
            setPage(prevPage => prevPage + 1);  // 加載下一頁
        }
    }, [loading, hasMore]);

    // 綁定滾動事件監聽器
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setLoading(true); // 開始加載
                const token = localStorage.getItem('token');
                if (!token) throw new Error('未找到 Token');

                // 修改請求 URL，添加 page 和 size 參數
                const response = await fetch(
                    `https://tongbro.ddns.net:8443/api/v1/user/getuserpost/${currentUserId}?page=${page}&size=10`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    },
                );

                // 處理響應
                if (!response.ok) {
                    const errorText = await response.text();
                    const errorResponse = JSON.parse(errorText);
                    if (errorResponse.message === 'Token has expired') {
                        alert('您的 Token 已過期，請重新登入。');
                        localStorage.removeItem('token');
                        window.location.href = '/';
                    } else {
                        throw new Error(`Failed to fetch posts: ${errorText}`);
                    }
                } else {
                    const data = await response.json();
                    if (Array.isArray(data)) {
                        if (data.length === 0) {
                            setHasMore(false); // 沒有更多文章
                        }

                        // 獲取作者資訊
                        const updatedPosts = await Promise.all(
                            data.map(async (post) => {
                                const userResponse = await fetch(
                                    `https://tongbro.ddns.net:8443/api/v1/user/profile/${post.userid}`,
                                    {
                                        method: 'GET',
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                            'Content-Type': 'application/json',
                                        },
                                    },
                                );

                                if (!userResponse.ok) {
                                    throw new Error('Failed to fetch username');
                                }

                                const userData = await userResponse.json();
                                return {
                                    ...post,
                                    author: userData.username,
                                };
                            }),
                        );

                        // 將新文章追加到現有文章列表
                        setPosts((prevPosts) => [...prevPosts, ...updatedPosts]);

                        // 獲取每篇文章的留言
                        updatedPosts.forEach((post) => {
                            fetchComments(post.id);
                        });

                        // 更新頭像
                        const profilePicsMap = {};
                        updatedPosts.forEach((post) => {
                            if (post.user && post.user.profilePic) {
                                profilePicsMap[post.user.userid] = post.user.profilePic;
                            }
                        });
                        setProfilePics((prevPics) => ({ ...prevPics, ...profilePicsMap }));
                    } else {
                        console.error('Expected an array but got:', data);
                        alert('API 返回的數據格式錯誤');
                    }
                }
            } catch (error) {
                console.error('Failed to fetch posts:', error);
                alert(`發生錯誤: ${error.message}`);
            } finally {
                setLoading(false); // 結束加載
            }
        };

        fetchPosts();
    }, [page, currentUserId, reset]); // 注意這裡的依賴包含了 page


    const handleDeletePost = (postId) => {
        setPostToDelete(postId);
        setConfirmDeleteVisible(true);
    };

    const handleCommentChange = (postId, value) => {
        setCommentInput((prev) => ({
            ...prev,
            [postId]: value,
        }));
    };

    const handleUpdateComment = (updatedComment) => {
        setComments((prevComments) => {
            const postId = updatedComment.postid;
            const updatedComments = Array.isArray(prevComments[postId])
                ? prevComments[postId].map((comment) =>
                    comment.messageid === updatedComment.messageid
                        ? updatedComment
                        : comment,
                )
                : [];

            return {
                ...prevComments,
                [postId]: updatedComments,
            };
        });
    };

    const handleDeleteComment = (comment) => {
        const postId = comment.postid;
        const updatedComments = Array.isArray(comments[postId])
            ? comments[postId].filter((c) => c.messageid !== comment.messageid)
            : [];

        setComments((prevComments) => ({
            ...prevComments,
            [postId]: updatedComments,
        }));
    };

    const formatDate = (date) => {
        return momentTimezone(date).tz('Asia/Taipei').locale('zh-tw').fromNow();
    };

    const onClose = () => {
        setSuccessMessage(null);
    };

    const isPostButtonDisabled = !postContent.trim() && !postImage;

    const handleEditPost = (post) => {
        setEditingPost(post);
    };

    return (
        <div className={postcontent.container}>
            {successMessage && <div className={postcontent.overlay} />}

            <div className={postcontent.inputContainer}>
                <input
                    type="text"
                    placeholder="分享你的生活..."
                    className={postcontent.inputPlaceholder}
                    onClick={() => setIsExpanded(!isExpanded)}
                    id="postInput"
                    readOnly
                />
            </div>

            <div
                className={`${postcontent.postArea} ${isExpanded ? postcontent.expanded : postcontent.collapsed
                    }`}
            >
                <textarea
                    value={postContent}
                    onChange={(e) => setPostContent(e.target.value)}
                    placeholder="輸入發文內容..."
                />

                <div className={postcontent.btaddress}>
                    <button
                        onClick={onHandlePostSubmit}
                        className={postcontent.postButton}
                        disabled={isPostButtonDisabled}
                    >
                        發文
                    </button>

                    <label
                        htmlFor="photoUpload"
                        className={postcontent.imgFileInput}
                    >
                        選擇照片
                    </label>
                    <input
                        type="file"
                        id="photoUpload"
                        accept="image/*"
                        onChange={onHandleImageChange}
                        className={postcontent.fileInput}
                    />

                    {postImage && (
                        <div className={postcontent.imagePreview}>
                            <img
                                src={URL.createObjectURL(postImage)}
                                alt="預覽圖片"
                                className={postcontent.previewImage}
                            />
                        </div>
                    )}
                </div>

                <select
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                    className={postcontent.visibilitySelect}
                >
                    <option value="公開">🌍 公開</option>
                    <option value="私人">🔒 私人</option>
                </select>
            </div>

            <div className={postcontent.posts}>
                {posts.map((post, index) => (
                    <div
                        key={index}
                        className={postcontent.post}
                        id="postContentContainer"
                    >
                        <div className={postcontent.postHeader}>
                            <span>
                                <img
                                    src={
                                        post.profilePic ||
                                        profilePics[post.userid] ||
                                        defaultProfilePic
                                    }
                                    alt={`${post.author}的頭像`}
                                    className={postcontent.commentAvatar}
                                    onClick={() =>
                                        handleUserClick(post.userid)
                                    }
                                />
                            </span>
                            <div
                                className={postcontent.postAuthor}
                                onClick={() => handleUserClick(post.userid)}
                            >
                                {post.author}
                            </div>
                            <div className={postcontent.postTime}>
                                <div>{formatDate(post.time)}</div>
                            </div>
                            <div className={postcontent.postVisibility}>
                                <div>
                                    {post.visibility === '公開'
                                        ? '🌍'
                                        : '🔒'}
                                </div>
                            </div>
                            {post.edited && (
                                <div className={postcontent.editedLabel}>
                                    （已編輯）
                                </div>
                            )}
                        </div>
                        <div className={postcontent.postContent}>
                            <div className={postcontent.postText}>
                                {post.content}
                            </div>
                            {post.image && (
                                <img
                                    src={post.image}
                                    className={postcontent.postImage}
                                    alt="附帶圖片"
                                />
                            )}
                        </div>
                        <LikeButton
                            postId={post.id}
                            initialLikes={post.likes}
                            currentUserId={currentUserId}
                            onLikeChange={handleLikeChange}
                        />
                        <CommentSection
                            postId={post.id}
                            comments={comments[post.id] || []}
                            commentInput={commentInput}
                            onCommentChange={handleCommentChange}
                            onCommentSubmit={onHandleCommentSubmit}
                            currentUserId={currentUserId}
                            onUpdateComment={handleUpdateComment}
                            onDeleteComment={handleDeleteComment}
                        />

                        {post.userid === currentUserId && (
                            <div className={postcontent.dropdown}>
                                <button
                                    className={postcontent.dropdownButton}
                                >
                                    ⋮
                                </button>
                                <div
                                    className={postcontent.dropdownContent}
                                >
                                    <button
                                        onClick={() => handleEditPost(post)}
                                    >
                                        編輯文章
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleDeletePost(post.id)
                                        }
                                    >
                                        刪除文章
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {successMessage && (
                <PostSuccessModal message={successMessage} onClose={onClose} />
            )}
            {editingPost && (
                <EditPostModal
                    post={editingPost}
                    onSave={onHandleSaveEditedPost}
                    onClose={() => setEditingPost(null)}
                />
            )}
            {isConfirmDeleteVisible && (
                <ConfirmDeleteModal
                    onClose={() => setConfirmDeleteVisible(false)}
                    onConfirm={onHandleConfirmDelete}
                />
            )}
            {deleteSuccessMessage && (
                <ConfirmDeleteSuccessModal
                    message={deleteSuccessMessage}
                    onClose={() => setDeleteSuccessMessage(null)}
                />
            )}
            {selectedUserId && (
                <UserProfileModal
                    userid={selectedUserId}
                    onClose={onCloseUserModal}
                />
            )}
        </div>
    );
};

export default PostContent;
