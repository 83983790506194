import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/ArticleManagement.module.css';

const getToken = () => localStorage.getItem('token');

const ArticleManagement = () => {
    const [posts, setPosts] = useState([]); // 保存文章列表
    const [currentPage, setCurrentPage] = useState(0); // 當前頁數
    const [totalPages, setTotalPages] = useState(0); // 總頁數
    const [selectedPost, setSelectedPost] = useState(null); // 被選中的文章
    const [showModal, setShowModal] = useState(false); // 控制模態框顯示
    const [loading, setLoading] = useState(false); // 加載狀態
    const [searchQuery, setSearchQuery] = useState(''); // 搜尋關鍵字

    // 當頁數或搜尋關鍵字變動時，自動抓取文章數據
    useEffect(() => {
        if (searchQuery.trim()) {
            handleSearch(searchQuery, currentPage);
        } else {
            fetchPosts(currentPage);
        }
    }, [currentPage, searchQuery]);

    const fetchPosts = async (page) => {
        setLoading(true); // 開始加載
        try {
            const token = getToken();
            if (!token) {
                console.error("Token 不存在");
                return;
            }

            const response = await axios.get(
                `https://tongbro.ddns.net:8443/api/v1/admin/posts?page=${page}&size=10`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            setPosts(response.data.content);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('获取文章数据时出错:', error);
        } finally {
            setLoading(false); // 加載結束
        }
    };

    const handleSearch = async (keyword, page) => {
        setLoading(true); // 開始加載
        try {
            const token = getToken();
            if (!token) {
                console.error("Token 不存在");
                return;
            }

            const response = await axios.get(
                `https://tongbro.ddns.net:8443/api/v1/admin/posts/search?keyword=${keyword}&page=${page}&size=10`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            setPosts(response.data.content);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('搜尋文章數據時出錯:', error);
        } finally {
            setLoading(false); // 加載結束
        }
    };

    // 查看文章詳情
    const handleViewMore = (post) => {
        setSelectedPost(post);
        setShowModal(true);
    };

    // 切換文章的刪除/恢復狀態
    const handleToggleIsdeleted = async (post) => {
        try {
            const token = getToken();
            await axios.put(
                `https://tongbro.ddns.net:8443/api/v1/admin/post/toggleIsdeleted/${post.id}`,
                {},
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            // 更新狀態
            setPosts(posts.map(p =>
                p.id === post.id ? { ...p, isdeleted: !p.isdeleted } : p
            ));
        } catch (error) {
            console.error('切換文章狀態時出錯:', error);
        }
    };

    // 頁碼更新
    const handlePreviousPage = () => {
        if (currentPage > 0) setCurrentPage(prevPage => prevPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) setCurrentPage(prevPage => prevPage + 1);
    };

    // 只在點擊關閉按鈕時關閉彈窗
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className={styles.container}>
            {loading && <div className={styles.loading}>加載中...</div>}
            <div className={styles.tableContainer}>
                <div className={styles.title}>文章管理列表</div>

                {/* 搜尋欄位 */}
                <input
                    type="text"
                    className={styles.searchInput}
                    placeholder="輸入關鍵字搜尋文章..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={styles.id}>編號</th>
                            <th className={styles.useremail}>使用者信箱</th>
                            <th className={styles.content}>文章內容</th>
                            <th className={styles.status}>狀態</th>
                            <th className={styles.function}>功能</th>
                        </tr>
                    </thead>
                    <tbody>
                        {posts.map((post, index) => (
                            <tr key={post.id}>
                                <td>{index + 1 + currentPage * 10}</td>
                                <td className={styles.useremail}>{post.author}</td>
                                <td>{post.content.length > 3 ? post.content.slice(0, 3) + '...' : post.content}</td>
                                <td>{post.isdeleted ? '已刪除' : '正常'}</td>
                                <td>
                                    <button className={styles.btn} onClick={() => handleViewMore(post)}>查看</button>
                                    <button className={styles.btn} onClick={() => handleToggleIsdeleted(post)}>
                                        {post.isdeleted ? '恢復' : '刪除'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={styles.pagination}>
                    <button className={styles.paginationButton} onClick={handlePreviousPage} disabled={currentPage === 0}>
                        上一頁
                    </button>
                    <span>第 {currentPage + 1} 頁 / 共 {totalPages} 頁</span>
                    <button className={styles.paginationButton} onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                        下一頁
                    </button>
                </div>

                {selectedPost && showModal && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <div className={styles.close} onClick={handleCloseModal}>✕</div>
                            <div className={styles.modalTitle}>文章詳情</div>
                            <div className={styles.modalBody}>{selectedPost.content}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ArticleManagement;
