import React, { useState } from 'react';
import entm from '../styles/ManualInputNutritionModal.module.css';
import { jwtDecode } from 'jwt-decode'; // 修正導入
import ReactDOM from 'react-dom';
import moment from 'moment';

// 獲取 Token 並解析出 userid
const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.userid || null;
        } catch (error) {
            console.error('Token 解析失敗:', error);
            return null;
        }
    }
    return null;
};

const ManualInputNutritionModal = ({ isOpen, onClose, handleSave }) => {
    const [editData, setEditData] = useState({
        calories: '',
        carb: '',
        protein: '',
        fat: '',
        sugar: '',
        sodium: '',
    });
    const userId = getUserIdFromToken();
    const token = localStorage.getItem('token');

    const handleChange = (e) => {
        const { name, value } = e.target;
        // 使用 parseFloat 將輸入值轉換為數字，並處理空值的情況
        setEditData((prevData) => ({
            ...prevData,
            [name]: value === '' ? '' : parseFloat(value),
        }));
    };

    const handleSubmit = async () => {
        // 加入時間戳
        const dataToSave = {
            ...editData,
            userid: userId,
            time: moment().utc().format(),
        };

        // 確保將 dataToSave 轉換為 JSON 格式
        const jsonDataToSave = JSON.stringify(dataToSave);
        console.log('要保存的數據（JSON）:', jsonDataToSave);

        try {
            // 發送 API 請求
            const response = await fetch('https://tongbro.ddns.net:8443/api/v1/user/image/save', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: jsonDataToSave,
            });

            if (!response.ok) {
                throw new Error(`HTTP 錯誤！狀態: ${response.status}`);
            }

            const result = await response.text();
            console.log('成功保存數據:', result);

            // 通知父組件數據保存完成
            handleSave(editData);
            onClose();
        } catch (error) {
            console.error('保存數據失敗:', error);
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={entm.modalOverlay}>
            <div className={entm.modalContent}>
                <div className={entm.modalBody}>
                    <div className={entm.modalTitle}>手動輸入營養數據</div>
                    <div className={entm.Labels}>
                        <label className={entm.InputLabel}>
                            卡路里 (kcal):
                            <input
                                name="calories"
                                type="number"
                                value={editData.calories}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            碳水化合物 (g):
                            <input
                                name="carb"
                                type="number"
                                value={editData.carb}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            脂肪 (g):
                            <input
                                name="fat"
                                type="number"
                                value={editData.fat}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            蛋白質 (g):
                            <input
                                name="protein"
                                type="number"
                                value={editData.protein}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            鈉 (mg):
                            <input
                                name="sodium"
                                type="number"
                                value={editData.sodium}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            糖 (g):
                            <input
                                name="sugar"
                                type="number"
                                value={editData.sugar}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                    </div>
                </div>
                <div className={entm.bts}>
                    <button className={entm.bt} onClick={handleSubmit}>保存</button>
                    <button className={entm.bt} onClick={onClose}>取消</button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ManualInputNutritionModal;
