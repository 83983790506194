import React, { useState, useEffect } from "react";
import login from "../styles/Login.module.css"; // 修改這裡

const ResetPasswordSuccessModal = ({ message }) => {
    const [dots, setDots] = useState("...");

    useEffect(() => {
        const timer = setInterval(() => {
            setDots((prev) => (prev.length < 3 ? prev + "." : "."));
        }, 500); // 每500毫秒增加一個點

        return () => clearInterval(timer);
    }, []);

    return (
        <div className={login.modal}>
            <div className={login.modal_content}>
                {/* 成功動畫勾選圖標 */}
                <div className={login.checkmark}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12l5 5L19 7" />
                    </svg>
                </div>
                {/* 成功訊息 */}
                <p>{message} {dots}</p>
            </div>
        </div>
    );
};

export default ResetPasswordSuccessModal;
