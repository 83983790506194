import React, { useState } from "react";
import uspSidebar from "../styles/UserProfileSidebar.module.css";
import { Link } from "react-router-dom";

const UserProfileSidebar = ({ setSelectedPage }) => {
  // 新增狀態來追蹤當前選中的頁面
  const [selectedPage, setSelectedPageState] = useState('profile'); // 預設為 'profile'

  const handleClick = (page) => {
    setSelectedPage(page);
    setSelectedPageState(page); // 更新狀態
  };

  return (
    <div className={uspSidebar.container}>
      <div className="row">
        <div className={uspSidebar.profileHeader}>
          <Link to="/home" className={uspSidebar.linkhome} id="userProfileBTH">⬅回到首頁</Link>

          <div className={uspSidebar.titlecontainer} id="userProfileSideBar">
            <div className={uspSidebar.title} id="userProfileTitle">會員中心</div>
            <div className={uspSidebar.textcontainer}>
              <div
                className={`${uspSidebar.titletext} ${selectedPage === 'profile' ? uspSidebar.active : ''}`}
                onClick={() => handleClick('profile')}
              >
                個人檔案
              </div>
              <div
                className={`${uspSidebar.titletext} ${selectedPage === 'info' ? uspSidebar.active : ''}`}
                onClick={() => handleClick('info')}
              >
                會員資料
              </div>
              <div
                className={`${uspSidebar.titletext} ${selectedPage === 'password' ? uspSidebar.active : ''}`}
                onClick={() => handleClick('password')}
              >
                更改密碼
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileSidebar;
