// UserProfileModal.js
import React, { useState, useEffect } from 'react';
import uspm from '../styles/UserProfileModel.module.css';
import ReactDOM from 'react-dom';
import defaultProfilePic from '../assets/defaultProfilePic.png';

const UserProfileModal = ({ userid, onClose }) => {
    const [user, setUser] = useState(null); // 儲存用戶信息
    const [loading, setLoading] = useState(true); // 控制加載狀態
    const [error, setError] = useState(null); // 控制錯誤狀態

    // 獲取用戶資料的函數
    const fetchUserProfile = async (userid) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('未找到 Token');

            const response = await fetch(
                `https://tongbro.ddns.net:8443/api/v1/user/userprofile/${userid}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );

            if (!response.ok) {
                throw new Error('無法獲取用戶信息');
            }

            const userData = await response.json();
            setUser(userData); // 儲存用戶信息
            setLoading(false); // 停止加載
        } catch (error) {
            console.error('錯誤:', error);
            setError('無法獲取用戶資料');
            setLoading(false);
        }
    };

    // 當組件渲染時調用 fetchUserProfile
    useEffect(() => {
        if (userid) {
            fetchUserProfile(userid);
        }
    }, [userid]);

    const formattedBirthday = user?.birthday ? user.birthday.substring(5) : '';

    if (loading) {
        console.log('Loading...');
    }

    if (error) {
        console.log('Error:', error);
    }

    return ReactDOM.createPortal(
        <div className={uspm.modalOverlay}>
            <div className={uspm.modalContent}>
                <div className={uspm.closeButton} onClick={onClose}>
                    ×
                </div>
                {user && (
                    <div className={uspm.userInfo}>
                        <img
                            src={user.profilepic || defaultProfilePic}
                            alt={`${user.username} 的頭像`}
                            className={uspm.userAvatar}
                        />
                        <div className={uspm.profile}>
                            <div className={uspm.userName}>{user.username}</div>
                            <div className={uspm.userBirthday}>
                                🎂
                                {formattedBirthday
                                    ? user.birthday.substring(5)
                                    : ''}
                            </div>
                        </div>
                        <div className={uspm.userBio}>
                            {user.bio && user.bio.trim() !== ''
                                ? user.bio
                                : '哈囉，你今天用Fitfeed了嗎?'}
                        </div>
                    </div>
                )}
            </div>
        </div>,
        document.body,
    );
};

export default UserProfileModal;
