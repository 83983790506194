import React, { useState, useEffect } from 'react';
import NutritionTable from '../components/NutritionTable'; // 引入 NutritionTable 组件
import { format } from 'date-fns'; // 引入 date-fns 來格式化日期
import axios from 'axios'; // 用于发起 API 请求
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import np from '../styles/NutritionPage.module.css'; // 引入樣式
import EditNutritionTableModal from '../components/EditNutritionTableModal'; // 引入 EditNutritionTableModal

// Token 解析逻辑
const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userid || null;
    } catch (error) {
      console.error('Token 解析失敗:', error);
      return null;
    }
  }
  return null;
};

const getToken = () => {
  return localStorage.getItem('token');
};

const NutritionPage = () => {
  const [nutritionData, setNutritionData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedNutrition, setSelectedNutrition] = useState(null);

  useEffect(() => {
    fetchNutritionData(selectedDate);
  }, [selectedDate]);

  // 获取营养数据
  const fetchNutritionData = async (date) => {
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const token = getToken();
      const currentUserId = getUserIdFromToken();

      if (!token || !currentUserId) {
        console.error("Token 或者 User ID 不存在");
        return;
      }

      const response = await axios.get(
        `http://localhost:8080/api/v1/user/usernutri/${currentUserId}/date/${formattedDate}/totalnutrients`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setNutritionData(response.data);
    } catch (error) {
      console.error('获取营养数据时出错:', error);
    }
  };

  // 处理编辑操作
  const handleEdit = (nutrition) => {
    setSelectedNutrition(nutrition);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async (editedNutrition) => {
    try {
      const token = getToken();
      const formattedDateTime = format(new Date(editedNutrition.time), "yyyy-MM-dd'T'HH:mm:ss");

      const response = await axios.put(
        `http://localhost:8080/api/v1/user/nutrition/${editedNutrition.dataId}/date/${formattedDateTime}`,
        editedNutrition,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      // 更新本地数据
      setNutritionData(nutritionData.map(item => item.dataId === editedNutrition.dataId ? response.data : item));

      // 在成功保存後關閉 Modal
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('更新营养数据时出错:', error);
    }
  };


  // 删除记录
  const handleDelete = async (id) => {
    try {
      const token = getToken();
      await axios.put(
        `http://localhost:8080/api/v1/user/nutrition/${id}/delete`,
        {}, // 空的 request body
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      // 更新本地数据，隐藏被标记为删除的记录
      setNutritionData(nutritionData.filter(item => item.dataId !== id));
    } catch (error) {
      console.error('删除营养记录时出错:', error);
    }
  };

  const renderContent = () => {
    return (
      <>
        <NutritionTable
          nutritionData={nutritionData}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <EditNutritionTableModal
          item={selectedNutrition}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          handleSave={handleSaveEdit} // 使用 handleSave 來保存
        />
      </>
    );
  };

  return (
    <div>
      <div className={np.headerContainer} id="userProfileHeader">
        <div className={np.titlecontainer}>
          <Link to="/home" className={np.title}>
            <div className={np.title}>
              ⬅返回首頁
            </div>
          </Link>
        </div>
        <div className={np.header_icon}></div>
        <div style={{ display: 'flex', flex: '1 1 auto' }}>
          {renderContent()}
        </div>
        {/* 这里可以加入其他组件 */}
      </div>
    </div>
  );
};

export default NutritionPage;