import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import 'moment/locale/zh-tw';
import momentTimezone from 'moment-timezone';
import postcontent from "../styles/PostContent.module.css";
import PostSuccessModal from './PostSuccessModal';
import EditPostModal from './EditPostModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import ConfirmDeleteSuccessModal from './ConfirmDeleteSuccessModal';
import CommentSection from './CommentSection';
import defaultProfilePic from '../assets/defaultProfilePic.png';
import LikeButton from './LikeButton';

// 引入拆分後的處理函數
import { handleConfirmDelete } from './PostDelete';
import { handleSaveEditedPost } from './PostUpdate';
import { handleCommentSubmit } from './CommentSubmit';

// 解析 Token 中的 userid
const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userid || null; // 假設 token 中的 userid 為 "userid"
    } catch (error) {
      console.error('Token 解析失敗:', error);
      return null;
    }
  }
  return null;
};

const MyPost = () => {
  const [posts, setPosts] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);
  const currentUserId = getUserIdFromToken();
  const [editingPost, setEditingPost] = useState(null);
  const [isConfirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const [comments, setComments] = useState({});
  const [commentInput, setCommentInput] = useState({});
  const [profilePics, setProfilePics] = useState({});
  const [reset, setReset] = useState(false);

  const handleLikeChange = (postId, newLikes) => {
    setPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId ? { ...post, likes: newLikes } : post
      )
    );
  };

  // 調用拆分後的 handleConfirmDelete 函數
  const onHandleConfirmDelete = () => {
    handleConfirmDelete({
      postToDelete,
      setPosts,
      setDeleteSuccessMessage,
      setReset,
      setConfirmDeleteVisible,
      setPostToDelete,
    });
  };

  // 調用拆分後的 handleSaveEditedPost 函數
  const onHandleSaveEditedPost = (updatedPost) => {
    handleSaveEditedPost({
      updatedPost,
      setPosts,
      setEditingPost,
    });
  };

  // 調用拆分後的 handleCommentSubmit 函數
  const onHandleCommentSubmit = (postId) => {
    handleCommentSubmit({
      postId,
      commentInput,
      currentUserId,
      setCommentInput,
      fetchComments,
    });
  };

  // 獲取留言
  const fetchComments = async (postId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('未找到 Token，請重新登入');

      const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/messages/post/${postId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          setComments(prev => ({
            ...prev,
            [postId]: [],
          }));
          return;
        }
        const errorText = await response.text();
        throw new Error(`獲取留言失敗: ${errorText}`);
      }

      const data = await response.json();
      setComments(prev => ({
        ...prev,
        [postId]: data,
      }));
    } catch (error) {
      console.error('Failed to fetch comments:', error);
      alert(`獲取留言失敗: ${error.message}`);
      if (error.message.includes('Token')) {
        window.location.href = '/';
      }
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('未找到 Token');

        const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/getuserpost/${currentUserId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorText = await response.text();
          const errorResponse = JSON.parse(errorText);
          if (errorResponse.message === 'Token has expired') {
            alert('您的 Token 已過期，請重新登入。');
            localStorage.removeItem('token');
            window.location.href = '/';
          } else {
            throw new Error(`Failed to fetch posts: ${errorText}`);
          }
        } else {
          const data = await response.json();
          if (Array.isArray(data)) {
            const sortedPosts = data.sort((a, b) => new Date(b.time) - new Date(a.time));

            const updatedPosts = await Promise.all(sortedPosts.map(async post => {
              const userResponse = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/profile/${post.userid}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              });

              if (!userResponse.ok) {
                throw new Error('Failed to fetch username');
              }

              const userData = await userResponse.json();
              return {
                ...post,
                author: userData.username // 將 username 添加到每篇文章
              };
            }));

            setPosts(updatedPosts);

            updatedPosts.forEach(post => {
              fetchComments(post.id);
            });

            const profilePicsMap = {};
            updatedPosts.forEach(post => {
              if (post.user && post.user.profilePic) {
                profilePicsMap[post.user.userid] = post.user.profilePic;
              }
            });
            setProfilePics(profilePicsMap);
          } else {
            console.error('Expected an array but got:', data);
            alert('API 返回的數據格式錯誤');
          }
        }
      } catch (error) {
        console.error('Failed to fetch posts:', error);
        alert(`發生錯誤: ${error.message}`);
      }
    };

    fetchPosts();
  }, [currentUserId, reset]);

  const handleDeletePost = (postId) => {
    setPostToDelete(postId);
    setConfirmDeleteVisible(true);
  };

  const handleCommentChange = (postId, value) => {
    setCommentInput((prev) => ({
      ...prev,
      [postId]: value,
    }));
  };

  const handleUpdateComment = (updatedComment) => {
    setComments((prevComments) => {
      const postId = updatedComment.postid;
      const updatedComments = Array.isArray(prevComments[postId])
        ? prevComments[postId].map((comment) =>
          comment.messageid === updatedComment.messageid ? updatedComment : comment
        )
        : [];

      return {
        ...prevComments,
        [postId]: updatedComments,
      };
    });
  };

  const handleDeleteComment = (comment) => {
    const postId = comment.postid;
    const updatedComments = Array.isArray(comments[postId])
      ? comments[postId].filter(c => c.messageid !== comment.messageid)
      : [];

    setComments(prevComments => ({
      ...prevComments,
      [postId]: updatedComments,
    }));
  };

  const formatDate = (date) => {
    return momentTimezone(date).tz('Asia/Taipei').locale('zh-tw').fromNow();
  };

  const onClose = () => {
    setSuccessMessage(null);
  };

  const handleEditPost = (post) => {
    setEditingPost(post);
  };

  return (
    <div className={postcontent.container}>
      {successMessage && <div className={postcontent.overlay} />}
      <div className={postcontent.posts}>
        {posts.filter(post => post.userid === currentUserId && !post.is_deleted).length === 0 ? (
          <div className={postcontent.noPostsMessage}>
            你目前沒有貼文
          </div>
        ) : (
          posts
            .filter(post => post.userid === currentUserId) // 篩選顯示自己所有的公開和私人文章
            .map((post, index) => (
              <div key={index} className={postcontent.post} id="myPostContentContainer">
                <div className={postcontent.postHeader}>
                  <span>
                    <img
                      src={profilePics[post.userid] || defaultProfilePic}
                      alt={`${post.author}的頭像`}
                      className={postcontent.commentAvatar}
                    />
                  </span>
                  <div className={postcontent.postAuthor}>{post.author}</div>
                  <div className={postcontent.postTime}>
                    <div>{formatDate(post.time)}</div>
                  </div>
                  <div className={postcontent.postVisibility}>
                    <div>{post.visibility === '公開' ? '🌍' : '🔒'}</div>
                  </div>
                  {post.edited && <div className={postcontent.editedLabel}>（已編輯）</div>}
                </div>
                <div className={postcontent.postContent}>
                  <div className={postcontent.postText}>{post.content}</div>
                  {post.image && <img src={post.image} className={postcontent.postImage} alt="附帶圖片" />}
                </div>
                <LikeButton postId={post.id} initialLikes={post.likes} currentUserId={currentUserId} onLikeChange={handleLikeChange} />
                <CommentSection
                  postId={post.id}
                  comments={comments[post.id] || []}
                  commentInput={commentInput}
                  onCommentChange={handleCommentChange}
                  onCommentSubmit={onHandleCommentSubmit}
                  currentUserId={currentUserId}
                  onUpdateComment={handleUpdateComment}
                  onDeleteComment={handleDeleteComment}
                />

                {post.userid === currentUserId && (
                  <div className={postcontent.dropdown}>
                    <button className={postcontent.dropdownButton}>⋮</button>
                    <div className={postcontent.dropdownContent}>
                      <button onClick={() => handleEditPost(post)}>編輯文章</button>
                      <button onClick={() => handleDeletePost(post.id)}>刪除文章</button>
                    </div>
                  </div>
                )}
              </div>
            ))
        )}
      </div>

      {successMessage && <PostSuccessModal message={successMessage} onClose={onClose} />}
      {editingPost && (
        <EditPostModal
          post={editingPost}
          onSave={onHandleSaveEditedPost}
          onClose={() => setEditingPost(null)}
        />
      )}
      {isConfirmDeleteVisible && (
        <ConfirmDeleteModal
          onClose={() => setConfirmDeleteVisible(false)}
          onConfirm={onHandleConfirmDelete}
        />
      )}
      {deleteSuccessMessage && (
        <ConfirmDeleteSuccessModal
          message={deleteSuccessMessage}
          onClose={() => setDeleteSuccessMessage(null)}
        />
      )}
    </div>
  );
};

export default MyPost;