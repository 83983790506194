import React, { useState, useEffect } from 'react';
import styles from '../styles/AdminSidebar.module.css';

const AdminSidebar = ({ handleLogout, setCurrentPage }) => {
    const [activePage, setActivePage] = useState('userManagement'); // 預設頁面

    useEffect(() => {
        setCurrentPage(activePage); // 初始化頁面
    }, [activePage, setCurrentPage]);

    const handleItemClick = (page) => {
        setActivePage(page); // 設置當前選中的頁面
    };

    return (
        <div className={styles.sidebar}>
            <div className={styles.header_container}>
                <div className={styles.header_icon}></div>
                <div className={styles.headertext}>後台管理平台</div>
            </div>

            <div className={styles.sidebarlist}>
                <ul className={styles.navList}>
                    <li
                        onClick={() => handleItemClick('userManagement')}
                        className={`${styles.navItem} ${activePage === 'userManagement' ? styles.active : ''}`}
                    >
                        使用者管理列表
                    </li>
                    <li
                        onClick={() => handleItemClick('articleManagement')}
                        className={`${styles.navItem} ${activePage === 'articleManagement' ? styles.active : ''}`}
                    >
                        文章管理列表
                    </li>
                    <li
                        onClick={() => handleItemClick('commentManagement')}
                        className={`${styles.navItem} ${activePage === 'commentManagement' ? styles.active : ''}`}
                    >
                        留言管理列表
                    </li>
                    <li onClick={handleLogout} className={styles.logoutButton}>
                        登出
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AdminSidebar;
