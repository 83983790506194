import React, { useState, useEffect, useCallback } from 'react';
import imageCompression from 'browser-image-compression';
import analysis from '../styles/Analysis.module.css';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import ManualInputNutritionModal from './ManualInputNutritionModal';

const Analysis = ({ onClose }) => {
    const [postImage, setPostImage] = useState(null);
    const [analysisResult, setAnalysisResult] = useState(null);
    const [nutritionData, setNutritionData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(true);
    const [isCloseButtonVisible, setIsCloseButtonVisible] = useState(true); // 新增狀態
    const [totalNutrition, setTotalNutrition] = useState({
        calories: 0,
        carb: 0,
        protein: 0,
        fat: 0,
        sugar: 0,
        sodium: 0,
    });
    const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);
    const [isManualInputOpen, setIsManualInputOpen] = useState(false);

    useEffect(() => {

    }, [error, onClose]);

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            try {
                setLoading(true);
                const compressedFile = await imageCompression(file, {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                });

                setPostImage(compressedFile);
                setIsCloseButtonVisible(false); // 在這裡隱藏關閉按鈕
                const analysisResponse = await analyzeImage(compressedFile);

                if (analysisResponse) {
                    setAnalysisResult(analysisResponse);
                    setIsAnalysisComplete(true);
                } else {
                    handleError('分析失敗，無法取得結果');
                }
            } catch (error) {
                handleError('圖片處理失敗:', error);
            } finally {
                setLoading(false);
            }
        } else {
            resetImageInput();
        }
    };


    const analyzeImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://tongbro.ddns.net:8443/api/v1/user/upload', {
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` },
                body: formData,
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            return await response.json();
        } catch (error) {
            handleError('Error analyzing image:', error);
        }
    };

    const isNutritionDataValid = useCallback((data) => {
        const requiredKeys = ['total_calories', 'total_carbs', 'total_protein', 'total_fat', 'total_sugar', 'total_sodium'];
        const isValid = requiredKeys.every(key => data[key] && !isNaN(data[key]) && data[key] > 0);

        if (!isValid) {
            handleError('未能檢測到熱量資訊，請重新上傳圖片');
        }
        return isValid;
    }, []);

    const parseAnalysisResult = useCallback(() => {
        try {
            const parsedContent = JSON.parse(analysisResult.analysis.replace(/```json|```/g, '').trim());
            const nutritionDataParsed = JSON.parse(parsedContent.choices[0].message.content.replace(/```json|```/g, '').trim());

            if (isNutritionDataValid(nutritionDataParsed)) {
                setNutritionData(nutritionDataParsed);
                setTotalNutrition({
                    calories: nutritionDataParsed.total_calories || 0,
                    carb: nutritionDataParsed.total_carbs || 0,
                    protein: nutritionDataParsed.total_protein || 0,
                    fat: nutritionDataParsed.total_fat || 0,
                    sugar: nutritionDataParsed.total_sugar || 0,
                    sodium: nutritionDataParsed.total_sodium || 0,
                });
            }
        } catch (error) {
            handleError('無法解析分析結果', error);
        }
    }, [analysisResult, isNutritionDataValid]);

    useEffect(() => {
        if (analysisResult) {
            parseAnalysisResult();
        }
    }, [analysisResult, parseAnalysisResult]);

    const handleError = (message, error) => {
        console.error(message, error);
        setError(message);
    };

    const resetImageInput = () => {
        setPostImage(null);
        setIsAnalysisComplete(false);
    };

    const getUserIdFromToken = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                return decodedToken.userid || null;
            } catch (error) {
                console.error('Token 解析失敗:', error);
                return null;
            }
        }
        return null;
    };

    const saveAnalysisResult = async () => {
        const userId = getUserIdFromToken();
        if (!userId) return alert('無法獲取用戶 ID，無法保存數據。');

        const nutritionDataToSave = {
            userid: userId,
            time: moment().utc().format(),
            ...totalNutrition,
        };

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://tongbro.ddns.net:8443/api/v1/user/image/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(nutritionDataToSave),
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            setSaveSuccess(true);
            setIsSaveButtonVisible(false);  // 按鈕隱藏以防重複點擊
            setIsCloseButtonVisible(false);  // 隱藏關閉按鈕
            setTimeout(() => window.location.reload(), 3000);
        } catch (error) {
            handleError('保存分析結果失敗:', error);
        }
    };

    const handleManualInputOpen = () => {
        setIsManualInputOpen(true);
    };

    const handleManualSave = (manualData) => {
        setTotalNutrition(manualData);
        setIsManualInputOpen(false);
        setNutritionData(manualData);
        onClose();
        window.location.reload();
    };

    return (
        <div className={analysis.container}>
            {!isAnalysisComplete && (
                <>
                    <div className={analysis.title}>圖片飲食分析</div>
                    <div className={analysis.content}>上傳今天的餐點，記錄飲食攝取</div>
                </>
            )}
            {!postImage && (  // 僅當 postImage 為 null 時顯示按鈕
                <label htmlFor="analysisPhotoUpload" className={analysis.buttons}>
                    {'選擇照片'}
                </label>
            )}
            <input
                type="file"
                id="analysisPhotoUpload"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
            />
            {isCloseButtonVisible && (  // 僅當 isCloseButtonVisible 為 true 時顯示關閉按鈕
                <span className={analysis.closeButton} onClick={onClose}>✕</span>
            )}
            {loading && <div className={analysis.loading}><p>圖片分析中，請稍候...</p></div>}
            {error && <div className={analysis.error}>
                <p>❌圖片分析失敗: {error}</p>
                <div className={analysis.retryButtons}>
                    <button className={analysis.retryButton} onClick={handleManualInputOpen}>手動輸入數據</button>
                    <button className={analysis.retryButton} onClick={onClose}>回到首頁重試</button>
                </div>
            </div>}
            {postImage && !loading && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={URL.createObjectURL(postImage)} alt="preview" className={analysis.preview} />
                </div>
            )}
            {nutritionData && !error && (
                <div className={analysis.result}>
                    <h4>分析結果：</h4>
                    <p>熱量：{Math.round(nutritionData.total_calories)} kcal</p>
                    <p>碳水化合物：{Math.round(nutritionData.total_carbs)} g</p>
                    <p>蛋白質：{Math.round(nutritionData.total_protein)} g</p>
                    <p>脂肪：{Math.round(nutritionData.total_fat)} g</p>
                    <p>糖：{Math.round(nutritionData.total_sugar)} g</p>
                    <p>鈉：{Math.round(nutritionData.total_sodium)} mg</p>
                    {saveSuccess && <div className={analysis.success}><p>✅保存成功！3秒後自動關閉!</p></div>}
                    <div className={analysis.bts}>
                        {isSaveButtonVisible && !saveSuccess && (
                            <button onClick={saveAnalysisResult} className={analysis.saveButton}>保存結果</button>
                        )}
                        {!saveSuccess && (
                            <button onClick={onClose} className={analysis.saveButton}>取消保存</button>
                        )}
                    </div>
                </div>
            )}
            {/* 彈出手動輸入模態框 */}
            {isManualInputOpen && (
                <ManualInputNutritionModal
                    isOpen={isManualInputOpen}
                    onClose={() => setIsManualInputOpen(false)}
                    handleSave={handleManualSave}
                />
            )}
        </div>
    );
};

export default Analysis;
