export const handleConfirmDelete = async ({
    postToDelete,
    setPosts,
    setDeleteSuccessMessage,
    setReset,
    setConfirmDeleteVisible,
    setPostToDelete,
  }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('未找到 Token');
  
      const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/post/${postToDelete}/delete`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to delete post: ${errorText}`);
      }
  
      setPosts(prevPosts => prevPosts.filter(post => post.id !== postToDelete));
      setDeleteSuccessMessage('文章刪除成功');
    } catch (error) {
      console.error('Failed to delete post:', error);
      alert(`刪除失敗: ${error.message}`);
    } finally {
      setConfirmDeleteVisible(false);
      setPostToDelete(null);
    }
  };