import React from 'react';
import dntm from '../styles/DeleteNutritionTableModal.module.css';

const DeleteNutritionTableModal = ({ isOpen, onClose, handleConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className={dntm.modalOverlay}>
            <div className={dntm.modalContent}>
                <div className={dntm.modalBody}>
                    <div className={dntm.modalTitle}>確定要刪除這項紀錄嗎？</div>
                    <div className={dntm.bts}>
                        <button className={dntm.bt} onClick={handleConfirm}>確認刪除</button>
                        <button className={dntm.bt} onClick={onClose}>取消</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteNutritionTableModal;
