import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import styles from '../styles/AdminPage.module.css';
import UsertableManagement from '../components/UsertableManagement';
import ArticleManagement from '../components/ArticleManagement';
import CommentManagement from '../components/CommentManagement';

const getToken = () => {
  return localStorage.getItem('token');
};

const AdminPage = () => {
  const [currentPage, setCurrentPage] = useState('userManagement'); // 預設顯示使用者管理頁面
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // 控制下拉選單顯示

  useEffect(() => {
    if (currentPage === 'userManagement') {
      fetchUserData();
    }
  }, [currentPage]);

  const fetchUserData = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.error("Token 不存在");
        return;
      }

      const response = await axios.get(
        'https://tongbro.ddns.net:8080/api/v1/admin/users',
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setUserData(response.data);
    } catch (error) {
      console.error('获取用户数据时出错:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleMenuClick = (page) => {
    setCurrentPage(page);
    setIsSidebarOpen(false); // 點擊選單後，關閉下拉式選單
  };

  const renderContent = () => {
    switch (currentPage) {
      case 'userManagement':
        return <UsertableManagement userData={userData} />;
      case 'articleManagement':
        return <ArticleManagement />;
      case 'commentManagement':
        return <CommentManagement />;
      default:
        return <UsertableManagement userData={userData} />;
    }
  };

  return (
    <div className={styles.adminPage}>
      {/* 漢堡按鈕 */}
      <button className={styles.hamburger} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        ☰
      </button>
      <div className={styles.header_container}>
        <div className={styles.header_icon}></div>
        <div className={styles.headertext}>後台管理平台</div>
      </div>
      {/* 下拉式選單（當漢堡按鈕被點擊後顯示） */}
      {isSidebarOpen && (
        <div className={styles.dropdownMenu}>
          <ul>
            <li onClick={() => handleMenuClick('userManagement')}>使用者管理</li>
            <li onClick={() => handleMenuClick('articleManagement')}>文章管理</li>
            <li onClick={() => handleMenuClick('commentManagement')}>留言管理</li>
            <li onClick={() => { handleMenuClick(''); handleLogout(); }}>登出</li>
          </ul>
        </div>
      )}

      {/* 固定側邊欄 */}
      <AdminSidebar
        handleLogout={handleLogout}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage} // 傳遞 currentPage 來控制選中項目
      />

      {renderContent()}
    </div>
  );
};

export default AdminPage;
