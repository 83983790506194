import React, { useState } from "react";
import axios from "axios";
import register from "../styles/Register.module.css";
import Profile from "./Profile";
import ProgressBar from './ProgressBar';

const Register = ({ onClose }) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        birthday: "",
        gender: ""
    });

    const [emailError, setEmailError] = useState(""); // 電子郵件錯誤狀態
    const [passwordError, setPasswordError] = useState(""); // 密碼錯誤狀態
    const [formError, setFormError] = useState(""); // 表單驗證錯誤
    const [showProfile, setShowProfile] = useState(false); // 控制 Profile 顯示的狀態
    const [profileData, setProfileData] = useState({});
    const [progress, setProgress] = useState(0);

    const checkEmailExists = async (email) => {
        try {
            const response = await axios.post("https://tongbro.ddns.net:8443/api/v1/guest/checkemail", email, {
                headers: {
                    "Content-Type": "text/plain"
                }
            });
            return response.data.exists;
        } catch (err) {
            console.error(err);
            return false; // 默認為 false，以防止影響其他邏輯
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, [name]: value };

            if (name === "email") {
                setEmailError(""); // 清除電子郵件錯誤
                checkEmailExists(value).then(exists => {
                    if (exists) {
                        setEmailError("電子郵件已存在");
                    }
                });
            } else if (name === "password" || name === "confirmPassword") {
                // 清除密碼錯誤，等待確認密碼變更後再檢查
                setPasswordError("");
            }

            return updatedFormData;
        });
    };

    const handleConfirmPasswordChange = (e) => {
        const { value } = e.target;
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, confirmPassword: value };

            // 檢查密碼是否匹配
            if (updatedFormData.password !== updatedFormData.confirmPassword) {
                setPasswordError("密碼不匹配");
            } else {
                setPasswordError(""); // 清除密碼錯誤
            }

            return updatedFormData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        // 先檢查電子郵件
        const emailExists = await checkEmailExists(formData.email);
        if (emailExists) {
            setEmailError("電子郵件已存在");
            return;
        }

        // 檢查密碼匹配
        if (formData.password !== formData.confirmPassword) {
            setPasswordError("密碼不匹配");
            return;
        }

        // 如果沒有錯誤，清空錯誤訊息
        setEmailError("");
        setPasswordError("");
        setFormError("");

        // 保存註冊資料到 Profile 表單
        const registrationData = {
            username: formData.name,
            email: formData.email,
            password: formData.password,
            birthday: formData.birthday,
            gender: formData.gender
        };


        // 顯示 Profile 表單，並將註冊資料傳遞給 Profile 表單
        setProfileData(registrationData);
        setShowProfile(true);
    };

    return (

        <div>
            
            {!showProfile ? ( // showProfile 為 false 時顯示註冊表單
                <div className={register.register_container}>
                    <ProgressBar progress={progress} />
                    <button className={register.close_button} onClick={onClose}>✕</button>
                    <label className={register.register_totaltitle}>註冊</label>
                    <label className={register.register_totaltitlecontent}>趕快加入我們吧!</label>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div className={register.form_columns}>
                            <div className={register.left_column}>
                                <div className={register.label_with_error}>
                                    <label className={register.register_title}>電子郵件：</label>
                                    {emailError && <p className={register.error_message}>{emailError}</p>}
                                </div>
                                <input
                                    className={register.input}
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="請輸入電子郵件地址"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />

                                <div className={register.label_with_error}>
                                    <label className={register.register_title}>密碼：</label>
                                </div>
                                <input
                                    className={register.input}
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="請輸入密碼"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />

                                <div className={register.label_with_error}>
                                    <label className={register.register_title}>再次輸入密碼：</label>
                                    {passwordError && <p className={register.error_message}>{passwordError}</p>}
                                </div>
                                <input
                                    className={register.input}
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    placeholder="請再次輸入密碼"
                                    value={formData.confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                />
                            </div>

                            <div className={register.right_column}>
                                <label className={register.register_title}>姓名：</label>
                                <input
                                    className={register.input}
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="請輸入您的姓名"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />

                                <label className={register.register_title}>生日：</label>
                                <input
                                    className={register.input}
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    value={formData.birthday}
                                    onChange={handleChange}
                                    required
                                />

                                <label className={register.register_title}>性別：</label>
                                <select
                                    className={register.input}
                                    id="gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    required
                                >
                                    <option className={register.inputgender} value="">請選擇性別</option>
                                    <option className={register.inputgender} value="男性">男</option>
                                    <option className={register.inputgender} value="女性">女</option>
                                </select>
                            </div>
                        </div>

                        {/* 顯示表單驗證錯誤訊息 */}
                        {formError && <p className={register.error_message}>{formError}</p>}

                        <button type="submit" className={register.rbt}>下一頁</button>

                    </form>
                </div>
            ) : ( // showProfile 為 true 時顯示 Profile 表單
                <Profile
                    onClose={onClose}
                    registrationData={profileData}
                    setProgress={setProgress}
                    progress={progress}
                />
            )}
        </div>
    );
};

export default Register;
