import React, { useEffect } from "react";
import iem from "../styles/InputEmailModal.module.css";

const InputEmailModal = ({ message, onClose }) => {
    useEffect(() => {
        // 設置3秒後關閉所有彈窗
        const timer = setTimeout(() => {
            onClose();
        }, 3000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className={iem.modal}>
            <div className={iem.modal_content}>
                {/* 成功動畫驚嘆號圖標 */}
                <div className={iem.exclamation}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={iem.icon}>
                        <circle cx="12" cy="12" r="10" stroke="red" strokeWidth="2" fill="none" />
                        <path d="M12 8v4" />
                        <circle cx="12" cy="16" r="1" />
                    </svg>
                </div>
                {/* 成功訊息 */}
                <p>{message}</p>
            </div>
        </div>
    );
};

export default InputEmailModal;
