import React from 'react';
import RightSidebar from '../components/RightSidebar'; // 假設這是 RightSidebar 的組件路徑
import rsp from '../styles/RightSidebarPage.module.css'; // 引入樣式
import { Link } from 'react-router-dom';

const RightSidebarPage = () => {

  return (
    <div className={rsp.header_container}>
      <div className={rsp.header_icon}></div>
      <div className={rsp.titlecontainer}>
        <Link to="/home" className={rsp.title}>
          <div className={rsp.title}>
            ⬅返回上一頁
          </div>
        </Link>
      </div>
      <RightSidebar />
    </div>

  );
};

export default RightSidebarPage;
