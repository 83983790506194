import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // 使用 useNavigate 替代 useHistory
import SaveChatModal from './SaveChatModal'; // 引入 SaveChatModal
import uspSidebar from "../styles/AssistantSidebar.module.css";

const AssistantSidebar = ({ messages }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate(); // 使用 useNavigate 來處理導航

  const handleHomeClick = (e) => {
    e.preventDefault(); // 阻止直接導航
    setIsModalOpen(true); // 打開保存聊天記錄的彈窗
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // 關閉彈窗
    navigate("/home"); // 導航回首頁
  };

  const handleDownloadChat = () => {
    // 這裡實現下載聊天記錄的邏輯
    const element = document.createElement("a");
    const file = new Blob([messages.map((msg) => `${msg.username}: ${msg.content}`).join('\n')], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "chat_history.txt";
    document.body.appendChild(element);
    element.click();
    setIsModalOpen(false); // 關閉彈窗
    navigate("/home"); // 導航回首頁
  };

  return (
    <div className={uspSidebar.container}>
      <div className={uspSidebar.sideBarContainer}>
        <div className="row">
          <div className={uspSidebar.profileHeader}>
            {/* 修改為觸發彈窗的Link */}
            <Link to="/home" onClick={handleHomeClick} className={uspSidebar.linkhome}>⬅回到首頁</Link>
            <div className={uspSidebar.titlecontainer}>
              <div className={uspSidebar.title} id="AssistantTitle">營養助手</div>
            </div>
          </div>
        </div>
      </div>

      {/* 引入 SaveChatModal 並控制顯示 */}
      {isModalOpen && (
        <SaveChatModal
          className={uspSidebar.saveChatModal}
          onClose={handleCloseModal}
          onDownload={handleDownloadChat}
          messages={messages}
        />
      )}
    </div>
  );
};

export default AssistantSidebar;
