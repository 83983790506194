import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import assistant from "../styles/Assistant.module.css";
import AIRobot from '../assets/AIRobot.png';
import defaultProfilePic from '../assets/defaultProfilePic.png';

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userid || null; // 假設 token 中的 userid 為 "userid"
    } catch (error) {
      console.error('Token 解析失敗:', error);
      return null;
    }
  }
  return null;
};

const Assistant = ({ setMessages }) => {
  const [localMessages, setLocalMessages] = useState([]); // 本地的 messages 狀態
  const [messageInput, setMessageInput] = useState('');
  const messageAreaRef = useRef(null);
  const userId = getUserIdFromToken(); // 這裡可以動態獲取用戶ID
  const isConnected = true; // 這裡可以根據實際情況判斷是否已連接
  const [profilePic, setProfilePic] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token'); // 從 localStorage 中獲取 token

        const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/profile/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // 攜帶 token
          },
        });

        const data = await response.json();
        console.log(data);
        setProfilePic(data.profilepic || defaultProfilePic); // 設定用戶的頭像
        setUsername(data.username); // 設定用戶名
      } catch (error) {
        console.error('獲取用戶資料失敗:', error);
      }
    };

    // 組件加載時獲取用戶資料
    fetchUserProfile();
  }, [userId]); // 添加 userId 作為依賴

  const sendMessage = async (e) => {
    e.preventDefault();

    if (messageInput.trim()) {
      // 添加用戶輸入的訊息到訊息列表中
      const userMessage = {
        userid: userId,
        username: username,
        profilePic: profilePic, // 用戶頭像
        content: messageInput,
        type: 'CHAT',
      };
      const newMessages = [...localMessages, userMessage]; // 更新本地訊息
      setLocalMessages(newMessages); // 更新本地的 messages 狀態
      setMessages(newMessages); // 更新父組件的 messages 狀態

      // 清空輸入框
      setMessageInput('');

      try {
        // 從 localStorage 獲取 token
        const token = localStorage.getItem('token');

        // 發送 API 請求到後端，並將 token 加入到 Authorization header
        const response = await axios.post(
          'https://tongbro.ddns.net:8443/api/v1/user/aibot',
          {
            userid: userId,
            prompt: messageInput,
          },
          {
            headers: {
              "Authorization": `Bearer ${token}`, // 在請求中加入 token
              "Content-Type": "application/json", // 請求內容為 JSON
            },
          }
        );

        // 從後端接收 AI 的回應並更新訊息列表
        const aiMessage = {
          userid: 'ai-bot',
          username: 'AI 營養助手',
          profilePic: AIRobot, // AI 頭像
          content: response.data.aiContent,
          type: 'CHAT',
        };
        const updatedMessages = [...newMessages, aiMessage];
        setLocalMessages(updatedMessages); // 更新本地 messages
        setMessages(updatedMessages); // 更新父組件的 messages 狀態
      } catch (error) {
        console.error('發送訊息失敗:', error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage(e);
    }
  };

  useEffect(() => {
    // 滾動到最新的訊息
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  }, [localMessages]);

  return (
    <div>
      {isConnected ? (
        <div className={assistant.chatPage}>
          <ul ref={messageAreaRef} className={assistant.messageArea}>
            {localMessages.map((msg, index) => (
              <li
                key={index}
                className={msg.userid === userId ? assistant.ownMessage : assistant.otherMessage}
              >
                <div className={assistant.messageHeader}>
                  {msg.type === 'CHAT' && (
                    <img
                      src={msg.profilePic || defaultProfilePic} // 如果 msg.profilePic 為空，則使用默認頭像
                      alt={`${msg.username}的頭像`}
                      className={assistant.chatAvatar}
                    />
                  )}
                  <div className={assistant.username}>{msg.username}</div>
                </div>
                <div className={assistant.content}>{msg.content}</div>
              </li>
            ))}
          </ul>
          <div className={assistant.warning}>
            此聊天紀錄無備份，如有需要請自行備份。
          </div>
        </div>
      ) : (
        <div className={assistant.usernamePage}>
          <p>連線中...</p>
        </div>
      )}

      <form className={assistant.form} onSubmit={sendMessage}>
        <input
          className={assistant.input}
          type="text"
          placeholder="輸入訊息..."
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          onKeyDown={handleKeyDown}
          required
        />
        <button
          className={`${assistant.button} ${messageInput.trim() ? assistant.buttonActive : ''}`}
          type="submit"
          disabled={!messageInput.trim()}
        ></button>
      </form>
    </div>
  );
};

export default Assistant;
