import React, { useState, useEffect } from 'react';
import epm from "../styles/EditPostModal.module.css"; // 引入樣式
import imageCompression from 'browser-image-compression';
import ReactDOM from 'react-dom';

const EditPostModal = ({ post, onClose, onSave }) => {
  const [editedContent, setEditedContent] = useState(post.content);
  const [editedImage, setEditedImage] = useState(post.image);
  const [visibility, setVisibility] = useState(post.isPublic ? '公開' : '私人'); // 使用字符串來表示公開/私人狀態

  // 處理圖片變更
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(file, options);
        const base64Image = await convertImageToBase64(compressedFile);
        setEditedImage(base64Image);
      } catch (error) {
        console.error('圖片壓縮失敗:', error);
        alert(`圖片壓縮失敗: ${error.message}`);
      }
    } else {
      setEditedImage(null);
    }
  };

  // 圖片轉換為 Base64
  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSave = () => {
    const updatedPost = {
      ...post,
      content: editedContent,
      image: editedImage,
      visibility: visibility, // 改為 visibility
    };
    onSave(updatedPost);
    onClose(); // 儲存後關閉彈窗
  };


  useEffect(() => {
    // 在彈窗打開時禁用滾動
    document.body.style.overflow = 'hidden';

    // 當彈窗關閉時恢復滾動
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={epm.modal}>
      <div className={epm.modalContent}>
        <span className={epm.close} onClick={onClose}>&times;</span>
        <div className={epm.title}>編輯文章</div>

        <textarea
          className={epm.content}
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          placeholder="編輯發文內容..."
        />

        {/* 隱藏原始的 input */}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          id="uploadInput"
          style={{ display: 'none' }} // 隱藏 input
        />

        {editedImage && (
          <div className={epm.imagePreview}>
            <img src={editedImage} alt="編輯圖片預覽" className={epm.previewImage} />
          </div>
        )}
        <div className={epm.btss}>
          {/* 客製化的上傳按鈕 */}
          <button
            className={epm.customUploadButton}
            onClick={() => document.getElementById('uploadInput').click()}
          >
            選擇圖片
          </button>
          {/* 公開/私人切換下拉選單 */}
          <div className={epm.visibilityToggle}>
            <select
              value={visibility}
              onChange={(e) => setVisibility(e.target.value)}
              className={epm.visibilitySelect}
            >
              <option value="公開">🌍 公開</option>
              <option value="私人">🔒 私人</option>
            </select>
          </div>
          <button onClick={handleSave} className={epm.saveButton}>保存變更</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default EditPostModal;
