export const handleSaveEditedPost = async ({
    updatedPost,
    setPosts,
    setEditingPost,
  }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('未找到 Token');
  
      const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/post/${updatedPost.id}/editpost`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedPost),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to edit post: ${errorText}`);
      }
  
      const updatedData = await response.json();
      const currentUserId = updatedData.userid;
  
      const userResponse = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/profile/${currentUserId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!userResponse.ok) {
        const errorText = await userResponse.text();
        throw new Error(`Failed to fetch user profile: ${errorText}`);
      }
  
      const userData = await userResponse.json();
      const author = userData.username;
  
      setPosts(prevPosts =>
        prevPosts.map(post =>
          post.id === updatedData.id
            ? { ...post, ...updatedData, edited: true, author }
            : post
        )
      );
  
      setEditingPost(null);
    } catch (error) {
      console.error('Failed to edit post:', error);
      alert(`編輯失敗: ${error.message}`);
    }
  };