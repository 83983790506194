import React, { useState } from "react";
import rpd from "../styles/ResetPassword.module.css";
import ResetPasswordSuccessModal from "./ResetPasswordSuccessModal"; // 引入彈窗組件

const ResetPassword = () => {
    const [error, setError] = useState("");
    const [resetPassword, setResetPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [success, setSuccess] = useState(false); // 控制彈窗顯示

    const handleReset = async (event) => {
        event.preventDefault();
        const token = new URLSearchParams(window.location.search).get('token');

        if (resetPassword !== confirmPassword) {
            setError("兩次輸入的密碼不相同。");
            return;
        } else {
            setError(""); // 清空錯誤提示
        }

        console.log("token:", token);
        console.log("resetPassword:", resetPassword);
        const response = await fetch('https://tongbro.ddns.net:8443/api/v1/guest/resetpassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token,
                newPassword: resetPassword,
            }),
        });

        if (response.ok) {
            setSuccess(true); // 顯示彈窗
            setTimeout(() => {
                window.location.href = "/"; // 2秒後導回首頁
            }, 2000);
        } else {
            setError("密碼重設失敗");
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (event.target.value !== resetPassword) {
            setError("兩次輸入的密碼不相同");
        } else {
            setError(""); // 清空錯誤提示
        }
    };

    return (
        <div className={rpd.container}>
            <div className={rpd.logo}></div>
            <form onSubmit={handleReset} className={rpd.form}>
                <div className={rpd.title}>重設密碼</div>
                {error && <div className={rpd.error}>{error}</div>}
                <input
                    className={rpd.input}
                    type="password"
                    id="resetpassword"
                    name="resetpassword"
                    placeholder="請輸入新密碼"
                    required
                    value={resetPassword}
                    onChange={(e) => setResetPassword(e.target.value)}
                />
                <input
                    className={rpd.input}
                    type="password"
                    id="confirmresetpassword"
                    name="confirmresetpassword"
                    placeholder="再次輸入新密碼"
                    required
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                />
                <button className={rpd.button} type="submit">送出</button>
            </form>
            {success && <ResetPasswordSuccessModal message="重設成功！跳至首頁中" />} {/* 彈窗 */}
        </div>
    );
};

export default ResetPassword;
