import React from 'react';
import logo from '../assets/fitfeedlogo.svg';
import oh from '../styles/OfficialHeader.module.css';

const OfficialHeader = () => {
    return (
        <div>
            <div className={oh.logo}>
                <img src={logo} alt="logo" />
            </div>
            <div className={oh.titleContent}>
                <h1>飲食管理分析社群</h1>

                <p>透過飲食來記錄你的生活</p>
            </div>
        </div>
    );
};

export default OfficialHeader;
