import React, { useState, useEffect } from 'react';
import emm from "../styles/EditMessageModal.module.css"; // 引入樣式
import ReactDOM from 'react-dom';

const EditMessageModal = ({ message, onClose, onUpdate }) => {
  // 確保 editedContent 的初始值是安全的
  const [editedContent, setEditedContent] = useState(message ? message.content : '');
  const [isLoading, setIsLoading] = useState(false); // 加入 loading 狀態
  const [errorMessage, setErrorMessage] = useState(''); // 加入錯誤狀態

  // 獲取 token
  const token = localStorage.getItem('token'); // 根據需要調整鍵值

  const handleSave = async () => {
    setIsLoading(true); // 開始保存時設置為加載中
    setErrorMessage(''); // 清除之前的錯誤信息

    const updatedMessage = {
      ...message,
      content: editedContent,
    };

    try {
      const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/messages/edit/${message.messageid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedMessage),
      });

      console.log('response:', response);

      if (!response.ok) {
        const errorData = await response.json();
        console.log("Error response:", errorData); // 打印後端返回的錯誤信息
        throw new Error('更新留言失敗');
      }
    
      const savedMessage = await response.json();
      onUpdate(savedMessage);
      onClose();

      console.log('savedMessage:', savedMessage);

    } catch (error) {
      console.error('錯誤:', error);
      setErrorMessage('更新失敗，請稍後再試');
    } finally {
      setIsLoading(false);
    }
  };

  // 使用 useEffect 監聽 message 的變化
  useEffect(() => {
    if (message) {
      setEditedContent(message.content);
    }
  }, [message]);

  useEffect(() => {
    // 在彈窗打開時禁用滾動
    document.body.style.overflow = 'hidden';

    // 當彈窗關閉時恢復滾動
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={emm.modal}>
      <div className={emm.modalContent}>
        <span className={emm.close} onClick={onClose}>&times;</span>
        <div className={emm.title}>編輯留言</div>

        {errorMessage && <p className={emm.error}>{errorMessage}</p>} {/* 顯示錯誤訊息 */}

        <textarea
          className={emm.content}
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          placeholder="編輯留言內容..."
        />
        <button
          onClick={handleSave}
          className={emm.saveButton}
          disabled={isLoading} // 保存時禁用按鈕
        >
          {isLoading ? '保存中...' : '保存變更'}
        </button>
      </div>
    </div>,
    document.body
  );
};

export default EditMessageModal;
