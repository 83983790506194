import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
    const token = localStorage.getItem('token'); // 只检查 token 是否存在
    const role = localStorage.getItem('role');

    if (token && role === 'USER') {
        return element;
    } else {
        return <Navigate to="/" />;
    }
};

export default PrivateRoute;