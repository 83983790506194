import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import LeftSidebar from '../components/LeftSidebar';
import PostContent from '../components/PostContent';
import RightSidebar from '../components/RightSidebar';
import Analysis from '../components/Analysis'; // 引入 Analysis 組件
import homepage from '../styles/HomePage.module.css';
import ChatRoom from '../components/ChatRoom';
import MyPost from '../components/MyPost';

const HomePage = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false); // 新增狀態
    const [activeComponent, setActiveComponent] = useState('home');
    const [refreshKey, setRefreshKey] = useState(0);

    const getUsernameFromToken = (token) => {
        if (!token) throw new Error('Token 不存在');
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return (
                            '%' +
                            ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join(''),
            );
            const parsedToken = JSON.parse(jsonPayload);
            if (!parsedToken.sub) throw new Error('無法解析用戶名');
            return parsedToken.sub;
        } catch (error) {
            console.error('Token 解析失敗:', error);
            throw new Error('Token 解析錯誤');
        }
    };

    // 確認 token 並進行重定向
    useEffect(() => {
        const checkToken = () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) throw new Error('未找到 token');
                const username = getUsernameFromToken(token); // 解析 token
                if (!username) throw new Error('用戶名解析失敗');
            } catch (error) {
                console.error('Token 無效或解析失敗:', error);
                localStorage.clear(); // 清空 localStorage
                console.log('localStorage 已清空');
                navigate('/'); // 重定向到首頁
            }
        };

        checkToken();

        // 每10秒檢查一次 token
        const interval = setInterval(checkToken, 10000);

        return () => clearInterval(interval);
    }, [navigate]);

    // 捕捉同步和異步錯誤
    useEffect(() => {
        const handleError = (msg, url, lineNo, columnNo, error) => {
            console.error(
                `Error: ${msg} at ${url}:${lineNo}:${columnNo}`,
                error,
            );
            localStorage.clear();
            console.log('localStorage 已清空');
            navigate('/');
            return false;
        };

        const handleUnhandledRejection = (event) => {
            console.error('Unhandled promise rejection:', event.reason);
            localStorage.clear();
            console.log('localStorage 已清空');
            navigate('/');
        };

        window.onerror = handleError;
        window.addEventListener('unhandledrejection', handleUnhandledRejection);

        return () => {
            window.onerror = null;
            window.removeEventListener(
                'unhandledrejection',
                handleUnhandledRejection,
            );
        };
    }, [navigate]);

    // 處理點擊圖標事件
    const handleIconClick = (icon) => {
        if (icon === 'home') {
            setRefreshKey((prevKey) => prevKey + 1); // 重新加載 home 組件
        }
        setActiveComponent(icon);
    };

    // 使用 useEffect 來控制背景滾動
    useEffect(() => {
        if (isAnalysisModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isAnalysisModalOpen]);

    // 渲染不同的內容
    const renderContent = () => {
        switch (activeComponent) {
            case 'home':
                return (
                    <PostContent
                        key={refreshKey}
                        setIsModalOpen={setIsModalOpen}
                        setIsAnalysisModalOpen={setIsAnalysisModalOpen}
                    />
                );
            case 'chat':
                return (
                    <ChatRoom
                        key={refreshKey}
                        setIsModalOpen={setIsModalOpen}
                    />
                );
            case 'profile':
                return (
                    <MyPost key={refreshKey} setIsModalOpen={setIsModalOpen} />
                );
            default:
                return (
                    <PostContent
                        key={refreshKey}
                        setIsModalOpen={setIsModalOpen}
                        setIsAnalysisModalOpen={setIsAnalysisModalOpen}
                    />
                );
        }
    };

    const openAnalysisModal = () => {
        setIsAnalysisModalOpen(true);
    };

    return (
        <div
            className={`${homepage.page} ${isModalOpen ? homepage.darkBackground : ''
                }`}
        >
            <Header
                onIconClick={handleIconClick}
                setIsAnalysisModalOpen={setIsAnalysisModalOpen}
            />
            <div className={homepage.container}>
                <div className={homepage.LeftSidebar}>
                    <LeftSidebar setIsAnalysisModalOpen={openAnalysisModal} />
                </div>
                <div className={homepage.MidContent}>{renderContent()}</div>
                <div className={homepage.RightSidebar}>
                    <RightSidebar />
                </div>
            </div>
            {/* 渲染 Analysis 彈窗 */}
            {isAnalysisModalOpen && (
                <div className={homepage.modalOverlay}>
                    <Analysis
                        onClose={() => setIsAnalysisModalOpen(false)}
                    />
                </div>
            )}
        </div>
    );
};

export default HomePage;
