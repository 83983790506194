import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import login from "../styles/Login.module.css";
import Register from "./Register";
import LoginSuccessModal from "./LoginSuccessModal";
import InputEmailModal from "./InputEmailModal";
import RestPasswordSuccessModal from "./RestPasswordSuccessModal";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
    const [showRegister, setShowRegister] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const navigate = useNavigate();

    const openRegister = () => setShowRegister(true);
    const closeRegister = () => setShowRegister(false);
    const closeEmailModal = () => setShowEmailModal(false);
    const closeResetPasswordModal = () => setShowResetPasswordModal(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        setErrorMessage(''); // 清除错误消息
        try {
            const response = await fetch("https://tongbro.ddns.net:8443/api/v1/guest/loginaction", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok && data.status) {
                console.log("登入成功:", data);
                localStorage.setItem('token', data.token);
                localStorage.setItem('role', data.user.role); // 確保從 data.user.role 獲取角色

                setShowSuccessModal(true);

                // 延遲4秒後再導航
                setTimeout(() => {
                    if (data.user.role === 'ADMIN') {
                        console.log("Navigating to AdminPage...");
                        navigate('/admin'); // 導航到 AdminPage
                    } else {
                        console.log("Navigating to HomePage...");
                        navigate('/home'); // 導航到一般用戶頁面
                    }
                }, 4000); // 4秒延遲

            } else {
                setErrorMessage(data.message || "登入失敗，請檢查帳號或密碼。");
            }
        } catch (error) {
            setErrorMessage("登入過程中發生錯誤，請重試。");
        }
    };

    const handleGoogleLoginSuccess = async (credentialResponse) => {
        console.log('Google Login Success:', credentialResponse);
        setErrorMessage(''); // 清除错误消息

        if (credentialResponse && credentialResponse.credential) {
            try {
                const { credential } = credentialResponse;
                const decodedToken = jwtDecode(credential);
                console.log('Decoded Token:', decodedToken);

                const res = await fetch('https://tongbro.ddns.net:8443/api/v1/guest/google/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: credential }),
                });

                const data = await res.json();
                console.log("data:", data);

                if (res.ok) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('role', data.role);  // 儲存單一字串的 role
                    localStorage.setItem('name', data.name);
                    localStorage.setItem('email', data.email);
                    console.log('Login Success: Token stored');

                    setShowSuccessModal(true); // 顯示成功彈窗

                    // 延遲4秒後再導航
                    setTimeout(() => {
                        if (data.role === 'ADMIN') {
                            console.log("Navigating to AdminPage...");
                            navigate('/admin');
                        } else {
                            console.log("Navigating to HomePage...");
                            navigate('/home');
                        }
                    }, 4000); // 4秒延遲

                } else {
                    setErrorMessage("您的帳號已被停權");
                }
            } catch (error) {
                console.error('Error during Google login:', error);
                setErrorMessage("Google 登入過程中發生錯誤，請重試。");
            }
        } else {
            console.error('No credential found in response');
            setErrorMessage('登入失敗，請重試。');
        }
    };

    const handleGoogleLoginFailure = (error) => {
        console.log('Google Login Failed:', error);
        setErrorMessage('Google 登入失敗，請重試。');
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        if (email === "") {
            setShowEmailModal(true);
            return;
        }

        try {
            const response = await fetch("https://tongbro.ddns.net:8443/api/v1/guest/forgotpassword", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setShowResetPasswordModal(true);
            } else {
                setErrorMessage("檢查電子郵件是否存在。");
            }
        } catch (error) {
            setErrorMessage("發送密碼重設郵件時發生錯誤，請重試。");
        }
    };

    return (
        <div className={login.container}>
            <div className={`${login.row} row`}>
                <div className={login.left_text}>
                    <div className={login.left_logo}></div>
                    <div className={`${login.left_text_content} left_text_content col-12 col-md-10`}>
                        透過飲食來紀錄你的生活，與世界的朋友共同享受零負擔的美食世界!
                    </div>
                </div>
                <div className={`${login.login_container} col-12 col-md-6`}>
                    <form onSubmit={handleLogin}>
                        <input
                            className={login.input}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="請輸入電子郵件地址"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            className={login.input}
                            type="password"
                            id="password"
                            name="password"
                            placeholder="請輸入密碼"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit" className={login.lbt}>登入</button>
                    </form>

                    <div className={login.googlelogincss}>
                        <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            theme="outline"
                            size="large"
                            text="signin_with"
                            shape="rectangular"
                            width="260px"
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        />
                    </div>

                    {errorMessage && <div className={login.error_message}>{errorMessage}</div>}
                    <span onClick={handleForgotPassword} className={login.forgot_text}>忘記密碼?</span>
                    <hr />
                    <button type="button" onClick={openRegister} className={login.rbt}>建立新帳號</button>
                    <div className={login.rtext}>
                        註冊即表示您同意
                        <a href=" " className={login.ratext}>服務條款</a>
                        和
                        <a href=" " className={login.ratext}>隱私政策</a>
                    </div>
                </div>
            </div>

            {showRegister && (
                <>
                    <div className={login.overlay}></div>
                    <div className={login.register_modal}>
                        <Register onClose={closeRegister} />
                    </div>
                </>
            )}

            {showSuccessModal && (
                <LoginSuccessModal message="登入成功！跳至主頁中" />
            )}

            {showEmailModal && (
                <InputEmailModal message="請先輸入信箱 !" onClose={closeEmailModal} />
            )}

            {showResetPasswordModal && (
                <RestPasswordSuccessModal
                    message={<>
                        密碼重設郵件已發送 !
                    </>}
                    onClose={closeResetPasswordModal}
                />
            )}
        </div>
    );
};

export default Login;