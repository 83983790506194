import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/zh-tw'; // 引入繁體中文語言包
import cs from '../styles/CommentSection.module.css'; // 使用 CSS 模組
import defaultProfilePic from '../assets/defaultProfilePic.png'; // 預設圖片
import EditMessageModal from './EditMessageModal'; // 引入編輯彈窗
import UserProfileModal from './UserProfileModal';

const DELETE_API_URL = 'https://tongbro.ddns.net:8443/api/v1/user/messages/delete';

const CommentSection = ({
    postId,
    comments = [],
    commentInput,
    onCommentChange,
    onCommentSubmit,
    currentUserId,
    onDeleteComment,
    onUpdateComment,
}) => {
    const formatDate = (date) => moment(date).fromNow();
    const commentList = comments.slice().reverse();

    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleDropdownToggle = (id) => {
        setDropdownVisible(dropdownVisible === id ? null : id);
    };

    const handleEdit = (comment) => {
        setSelectedComment(comment);
        setIsModalOpen(true);
        setDropdownVisible(null);
    };

    const handleUserClick = (userid) => {
        console.log('Selected User ID:', userid); // 確認用戶ID
        setSelectedUserId(userid); // 點擊用戶時打開彈窗
    };

    const onCloseUserModal = () => {
        setSelectedUserId(null); // 關閉彈窗
    };

    const handleDelete = async (comment) => {
        if (comment.userid !== currentUserId) {
            alert('您無權刪除此留言');
            return;
        }

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(
                `${DELETE_API_URL}/${comment.messageid}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                },
            );

            if (!response.ok) {
                throw new Error('刪除留言失敗');
            }

            onDeleteComment(comment);
            setDropdownVisible(null);
        } catch (error) {
            console.error('錯誤:', error);
        }
    };

    const handleUpdateComment = (updatedComment) => {
        updatedComment.edited = true; // 設置 edited 屬性為 true
        onUpdateComment(updatedComment);
        setIsModalOpen(false);
        setSelectedComment(null);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && commentInput[postId]?.trim()) {
            e.preventDefault(); // 阻止預設行為（如換行）
            onCommentSubmit(postId);
        }
    };

    return (
        <div className={cs.commentSection}>
            <div className={`${cs.commentList} ${cs.scrollable}`}>
                {commentList.length > 0 ? (
                    commentList.map((comment) => (
                        <div key={comment.messageid} className={cs.commentItem}>
                            <div className={cs.dropdown}>
                                {comment.userid === currentUserId && (
                                    <>
                                        <button
                                            className={cs.dropdownButton}
                                            onClick={() =>
                                                handleDropdownToggle(comment.id)
                                            }
                                        >
                                            ⋮
                                        </button>
                                        {dropdownVisible === comment.id && (
                                            <div className={cs.dropdownContent}>
                                                <button
                                                    className={
                                                        cs.dropdownButton
                                                    }
                                                    onClick={() =>
                                                        handleEdit(comment)
                                                    }
                                                >
                                                    編輯留言
                                                </button>
                                                <button
                                                    className={
                                                        cs.dropdownButton
                                                    }
                                                    onClick={() =>
                                                        handleDelete(comment)
                                                    }
                                                >
                                                    刪除留言
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className={cs.commentContentContainer}>
                                <span>
                                    <img
                                        src={
                                            comment.profilepic ||
                                            defaultProfilePic
                                        }
                                        alt={`${comment.username}的頭像`}
                                        className={cs.commentAvatar}
                                        onClick={() =>
                                            handleUserClick(comment.userid)
                                        }
                                    />
                                </span>
                                <span
                                    className={cs.commentAuthor}
                                    onClick={() =>
                                        handleUserClick(comment.userid)
                                    }
                                >
                                    {comment.username}
                                </span>
                                <span className={cs.commentTime}>
                                    {formatDate(comment.time)}
                                </span>
                                {comment.edited && (
                                    <span className={cs.editedLabel}>
                                        （已編輯）
                                    </span>
                                )}{' '}
                                {/* 顯示已編輯標籤 */}
                            </div>
                            <div className={cs.commentContent}>
                                {comment.content}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className={cs.noComments}>目前沒有留言</p>
                )}
            </div>

            <div className={cs.commentSections}>
                <input
                    type="text"
                    value={commentInput[postId] || ''}
                    onChange={(e) => onCommentChange(postId, e.target.value)}
                    placeholder="新增留言..."
                    className={cs.commentInput}
                    onKeyDown={handleKeyDown} // 按下 Enter 鍵觸發提交
                />
                <button
                    className={`${cs.commentButton} ${
                        commentInput[postId]?.trim()
                            ? cs.commentButtonActive
                            : ''
                    }`}
                    onClick={() => onCommentSubmit(postId)}
                    disabled={!commentInput[postId]?.trim()} // 當沒有內容時，禁用按鈕
                ></button>
            </div>

            {isModalOpen && (
                <EditMessageModal
                    message={selectedComment}
                    onClose={() => setIsModalOpen(false)}
                    onUpdate={handleUpdateComment}
                />
            )}
            {selectedUserId && (
                <UserProfileModal
                    userid={selectedUserId}
                    onClose={onCloseUserModal}
                />
            )}
        </div>
    );
};

export default CommentSection;
