import React, { useState, useEffect } from 'react';
import lefts from "../styles/LeftSidebar.module.css"; // 確保路徑正確
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import defaultProfilePic from '../assets/defaultProfilePic.png'; // 預設圖片

// 解析 Token 中的 userid
const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.userid || null; // 假設 token 中的 userid 為 "userid"
        } catch (error) {
            console.error('Token 解析失敗:', error);
            return null;
        }
    }
    return null;
};

const LeftSidebar = ({ setIsAnalysisModalOpen }) => {
    const [userData, setUserData] = useState(null); // 用來儲存用戶資料
    const currentUserId = getUserIdFromToken(); // 獲取當前使用者ID
    const [darkMode, setDarkMode] = useState(() => {
        // 在初次渲染時從 localStorage 中獲取深色模式狀態
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === 'true'; // 確保返回布爾值
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token'); // 從 localStorage 獲取 JWT token
                if (!token) {
                    // 未登入，重定向到登入頁面
                    window.location.href = '/';
                    return;
                }

                const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/profile/${currentUserId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // 攜帶 token
                    }
                });

                if (!response.ok) {
                    throw new Error('獲取用戶資料失敗');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData(); // 調用函數進行數據請求
    }, [currentUserId]);

    useEffect(() => {
        // 每次模式改變時，更新 body 的 class 並存儲到 localStorage
        if (darkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
        localStorage.setItem('darkMode', darkMode); // 儲存狀態到 localStorage
    }, [darkMode]);

    if (!userData) {
        return <p>Loading...</p>; // 數據還沒回來時顯示的 loading 狀態
    }

    // 判斷 profile_pic 是否為 null，為 null 則顯示預設圖片
    const profilePic = userData.profilepic ? userData.profilepic : defaultProfilePic;

    // 切換深色模式
    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
    };

    // 登出功能，清空 localStorage 並重定向到登入頁面
    const handleLogout = () => {
        localStorage.clear(); // 清除所有 localStorage 中的資料
        window.location.href = '/'; // 重定向到登入頁面
    };

    // 點擊飲食分析按鈕時觸發，打開彈窗
    const handleNavigateToAnalysis = () => {
        setIsAnalysisModalOpen(true);
    };

    return (
        <div className={lefts.container}>
            <div className="row">
                <div className={lefts.sidebar}>
                    <div className={lefts.profileHeader}>
                        {/* 使用條件渲染來顯示圖片 */}
                        <img
                            src={profilePic}
                            alt="Profile"
                            className={lefts.profilePic}
                        />
                        <div className={lefts.user} id="username">{userData.username}</div>
                    </div>
                    <div className={lefts.textcontainer}>
                        <div
                            onClick={handleNavigateToAnalysis}
                            className={lefts.titleeat}
                            id="eat"
                        >
                            飲食分析
                        </div>
                        <div className={lefts.title} id="memberInfo">
                            <Link to="/userprofile" className={lefts.link}>會員中心</Link>
                        </div>
                        <div className={lefts.title} id="post">
                            <Link to="/assistant" className={lefts.link}>營養助手</Link>
                        </div>
                        {/* 登出按鈕，點擊後觸發 handleLogout */}
                        <div className={lefts.logout} id="logout" onClick={handleLogout}>登出</div>
                    </div>
                    <div className={lefts.ptext} id="setting" onClick={toggleDarkMode}>
                        {darkMode ? "🌛 深色模式" : "🌞 淺色模式"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeftSidebar;