import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Client as Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { jwtDecode } from 'jwt-decode';
import cr from '../styles/ChatRoom.module.css';
import defaultProfilePic from '../assets/defaultProfilePic.png';
import UserProfileModal from './UserProfileModal';
import { format } from 'date-fns';

const ChatRoom = () => {
    const [isConnected, setIsConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageInput, setMessageInput] = useState('');
    const stompClientRef = useRef(null);
    const messageAreaRef = useRef(null);
    const isSending = useRef(false);
    const token = localStorage.getItem('token');
    const [userProfilePics, setUserProfilePics] = useState({});
    const [loadingPics, setLoadingPics] = useState(new Set());
    const [selectedUserId, setSelectedUserId] = useState(null);

    // 通用的 Token 解碼函數
    const decodeToken = () => {
        if (token) {
            try {
                return jwtDecode(token);
            } catch (error) {
                console.error('Token 解析失敗:', error);
                return {};
            }
        }
        return {};
    };

    const { username = '匿名', userid: userId = null } = decodeToken();

    // 滾動到底部
    const scrollToBottom = () => {
        if (messageAreaRef.current) {
            messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
        }
    };

    // 獲取用戶頭像的函數
    const fetchUserProfilePicsAndNames = useCallback(async (userIds) => {
        if (userIds.length === 0) return {};

        try {
            const url = 'https://tongbro.ddns.net:8443/api/v1/user/chat/picturesandnames';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // 添加 Authorization header
                },
                body: JSON.stringify(userIds),
            });

            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                console.error("無法獲取頭像, 狀態碼:", response.status);
                return {};
            }
        } catch (error) {
            console.error("獲取頭像時發生錯誤:", error);
            return {};
        }
    }, [token]);

    // 訊息接收處理
    const onMessageReceived = useCallback(async (payload) => {
        const message = JSON.parse(payload.body);
        console.log("Received message:", message);

        let userInfo = userProfilePics[message.userid];

        if (!userInfo && !loadingPics.has(message.userid)) {
            // 如果沒有緩存且目前沒有正在載入，才發送 API 請求
            setLoadingPics((prev) => new Set(prev).add(message.userid));

            const userInfos = await fetchUserProfilePicsAndNames([message.userid]);
            userInfo = userInfos[message.userid] || { username: message.sender, profilePic: defaultProfilePic };

            setUserProfilePics((prevPics) => ({
                ...prevPics,
                [message.userid]: userInfo,
            }));

            // 移除載入標記
            setLoadingPics((prev) => {
                const updated = new Set(prev);
                updated.delete(message.userid);
                return updated;
            });
        }

        const newMessage = {
            ...message,
            username: userInfo.username,
            profilePic: userInfo.profilePic,
            content: message.type === 'JOIN' ? `${userInfo.username} 加入!` : message.content,
            timestamp: message.timestamp,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
    }, [userProfilePics, fetchUserProfilePicsAndNames, loadingPics]);

    // 監聽 messages 變更，自動滾動到底部
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // 獲取聊天紀錄
    const fetchChatHistory = useCallback(async () => {
        try {
            const token = localStorage.getItem('token'); // 獲取 token
            if (!token) {
                console.error("無法獲取聊天紀錄，因為沒有 token");
                return;
            }

            const response = await fetch('https://tongbro.ddns.net:8443/api/v1/user/chat/history?limit=100', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // 攜帶 token
                }
            });

            if (response.ok) {
                const data = await response.json();
                const reversedData = data.reverse();

                const userIds = [...new Set(reversedData.map(msg => msg.userid))];
                const userInfos = await fetchUserProfilePicsAndNames(userIds);

                const messagesWithUserInfo = reversedData.map(msg => ({
                    ...msg,
                    username: userInfos[msg.userid]?.username || '匿名',
                    profilePic: userInfos[msg.userid]?.profilePic || defaultProfilePic,
                    timestamp: msg.timestamp,
                }));

                setMessages(messagesWithUserInfo);
            } else {
                console.error("無法獲取聊天紀錄, 狀態碼:", response.status);
            }
        } catch (error) {
            console.error("獲取聊天紀錄時發生錯誤:", error);
        }
    }, [fetchUserProfilePicsAndNames]);

    const connect = useCallback(() => {
        const socket = new SockJS('https://tongbro.ddns.net:8443/ws');
        const stompClient = new Stomp({
            webSocketFactory: () => socket,
            debug: console.log,
            reconnectDelay: 5000,
        });

        stompClient.onConnect = () => {
            console.log("Connected to WebSocket server"); // 確認連接成功
            setIsConnected(true);
            stompClient.subscribe('/topic/public', onMessageReceived);
        };

        stompClient.onStompError = (error) => {
            console.error('無法連接至 WebSocket 伺服器:', error);
        };

        stompClient.activate();
        stompClientRef.current = stompClient;
    }, [onMessageReceived]);

    useEffect(() => {
        if (username) {
            fetchChatHistory(); // 獲取聊天紀錄
            connect();
        }

        // 清理函數以關閉連接
        return () => {
            if (stompClientRef.current) {
                stompClientRef.current.deactivate();
                console.log("Disconnected from WebSocket server"); // 確認斷開連接
            }
        };
    }, [username, connect, fetchChatHistory]);

    // 發送訊息
    const sendMessage = useCallback((event) => {
        event.preventDefault();
        if (messageInput.trim() && stompClientRef.current && isConnected && !isSending.current) {
            isSending.current = true;

            const chatMessage = {
                sender: username,
                userid: userId, // 確保這裡有正確的 userId
                content: messageInput,
                type: 'CHAT',
            };

            console.log("Sending message:", chatMessage);

            stompClientRef.current.publish({
                destination: "/app/chat.sendMessage",
                body: JSON.stringify(chatMessage),
            });

            setMessageInput('');
            isSending.current = false;
        } else {
            console.error("無法發送訊息，請確認是否已連接");
        }
    }, [messageInput, username, userId, isConnected]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && messageInput?.trim()) {
            e.preventDefault(); // 阻止預設行為（如換行）
            sendMessage(e);
        }
    };

    const handleUserClick = (userid) => {
        console.log('Selected User ID:', userid); // 確認用戶ID
        setSelectedUserId(userid); // 點擊用戶時打開彈窗
    };

    const onCloseUserModal = () => {
        setSelectedUserId(null); // 關閉彈窗
    };

    return (
        <div>
            {isConnected ? (
                <div className={cr.chatPage}>
                    <ul ref={messageAreaRef} className={cr.messageArea}>
                        {messages.map((msg, index) => {
                            const formattedTime = msg.timestamp
                                ? format(new Date(msg.timestamp), 'yyyy-MM-dd HH:mm:ss')
                                : '';

                            return (
                                <li
                                    key={index}
                                    className={msg.userid === userId ? cr.ownMessage : cr.otherMessage}
                                >
                                    <div className={cr.messageHeader}>
                                        {msg.type === 'CHAT' && (
                                            <img
                                                src={msg.profilePic}
                                                alt={`${msg.username}的頭像`}
                                                className={cr.chatAvatar}
                                                onClick={() =>
                                                    handleUserClick(msg.userid)
                                                }
                                            />
                                        )}
                                        <div className={cr.username} onClick={() => handleUserClick(msg.userid)}>{msg.username}</div>
                                    </div>
                                    <div className={cr.content}>{msg.content}</div>
                                    {formattedTime && (
                                        <div className={cr.timestamp}>{formattedTime}</div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : (
                <div className={cr.usernamePage}>
                    <p>連線中...</p>
                </div>
            )}
            <form className={cr.form} onSubmit={sendMessage}>
                <input
                    className={cr.input}
                    type="text"
                    placeholder="輸入訊息..."
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    required
                />
                <button
                    className={`${cr.button} ${messageInput.trim() ? cr.buttonActive : ''}`}
                    type="submit"
                    disabled={!messageInput.trim()}
                ></button>
            </form>
            {selectedUserId && (
                <UserProfileModal
                    userid={selectedUserId}
                    onClose={onCloseUserModal}
                />
            )}
        </div>
    );

};

export default ChatRoom;
