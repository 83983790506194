import React, { useState, useEffect } from 'react';
import eum from '../styles/EditUserModal.module.css';

const EditUserModal = ({ user, isOpen, onClose, onSave }) => {
  const [editedUser, setEditedUser] = useState(user);

  useEffect(() => {
    setEditedUser(user);
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prevUser => ({ ...prevUser, [name]: value }));
  };

  if (!isOpen) return null;

  return (
    <div className={eum.modal}>
      <div className={eum.modal_content}>
        <div className={eum.modal_title}>編輯使用者</div>
        <label className={eum.modal_label} htmlFor="username">使用者名稱:
          <input
            type="text"
            name="username"
            value={editedUser.username || ''}
            onChange={handleChange}
            placeholder="Username"
          />
        </label>
        <label className={eum.modal_label} htmlFor='email'>電子郵件:
          <input
            type="email"
            name="email"
            value={editedUser.email || ''}
            onChange={handleChange}
            placeholder="Email"
          />
        </label>
        <label className={eum.modal_label} htmlFor='role'>權限:
          <input
            type="text"
            name="role"
            value={editedUser.role || ''}
            onChange={handleChange}
            placeholder="Role"
          />
        </label>
        <div className={eum.modal_buttons}>
          <button className={eum.modal_button} onClick={() => onSave(editedUser)}>保存</button>
          <button className={eum.modal_button} onClick={onClose}>取消</button>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
