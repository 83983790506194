import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../styles/CommentManagement.module.css';

const getToken = () => localStorage.getItem('token');

const CommentManagement = () => {
    const [comments, setComments] = useState([]); // 保存留言列表
    const [currentPage, setCurrentPage] = useState(0); // 當前頁數
    const [totalPages, setTotalPages] = useState(0); // 總頁數
    const [selectedComment, setSelectedComment] = useState(null); // 被選中的留言
    const [showModal, setShowModal] = useState(false); // 控制模態框顯示
    const [loading, setLoading] = useState(false); // 加載狀態
    const [searchKeyword, setSearchKeyword] = useState(''); // 搜索關鍵字

    // 當頁數變動時，自動抓取留言數據
    useEffect(() => {
        if (searchKeyword) {
            fetchMessagesWithSearch(currentPage, searchKeyword);
        } else {
            fetchComments(currentPage);
        }
    }, [currentPage, searchKeyword]);

    const fetchComments = async (page) => {
        setLoading(true); // 開始加載
        try {
            const token = getToken();
            if (!token) {
                console.error("Token 不存在");
                return;
            }

            const response = await axios.get(
                `https://tongbro.ddns.net:8443/api/v1/admin/comments?page=${page}&size=10`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            setComments(response.data.content);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('获取留言数据时出错:', error);
        } finally {
            setLoading(false); // 加載結束
        }
    };

    const fetchMessagesWithSearch = async (page, keyword) => {
        setLoading(true); // 開始加載
        try {
            const token = getToken();
            if (!token) {
                console.error("Token 不存在");
                return;
            }

            const response = await axios.get(
                `https://tongbro.ddns.net:8443/api/v1/admin/messages/search?keyword=${keyword}&page=${page}&size=10`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            setComments(response.data);
            setTotalPages(Math.ceil(response.data.length / 10)); // 根據返回的數據數量設定總頁數
        } catch (error) {
            console.error('搜尋留言時出錯:', error);
        } finally {
            setLoading(false); // 加載結束
        }
    };

    const handleViewMore = (comment) => {
        setSelectedComment(comment);
        setShowModal(true);
    };

    const handleToggleIsdeleted = async (comment) => {
        try {
            const token = getToken();
            await axios.put(
                `https://tongbro.ddns.net:8443/api/v1/admin/comment/toggleIsdeleted/${comment.messageid}`,
                {},
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            setComments(comments.map(c =>
                c.messageid === comment.messageid ? { ...c, isdeleted: !c.isdeleted } : c
            ));
        } catch (error) {
            console.error('切換留言狀態時出錯:', error);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
    };

    const handleSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        setCurrentPage(0); // 搜尋時重置頁數
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className={styles.container}>
            {loading && <div className={styles.loading}>加載中...</div>}
            <div className={styles.tableContainer}>
                <div className={styles.title}>留言管理列表</div>

                {/* 搜索框 */}
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder="輸入關鍵字搜尋留言"
                        value={searchKeyword}
                        onChange={handleSearchChange}
                        className={styles.searchInput}
                    />
                </div>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={styles.id}>編號</th>
                            <th className={styles.useremail}>使用者信箱</th>
                            <th className={styles.content}>留言內容</th>
                            <th className={styles.status}>狀態</th>
                            <th className={styles.function}>功能</th>
                        </tr>
                    </thead>
                    <tbody>
                        {comments.map((comment, index) => (
                            <tr key={comment.messageid}>
                                <td>{index + 1 + currentPage * 10}</td>
                                <td className={styles.useremail}>{comment.email}</td>
                                <td className={styles.content}>
                                    {comment.content.length > 3 ? comment.content.slice(0, 3) + '...' : comment.content}
                                </td>
                                <td>{comment.isdeleted ? '已刪除' : '正常'}</td>
                                <td>
                                    <button className={styles.btn} onClick={() => handleViewMore(comment)}>查看</button>
                                    <button className={styles.btn} onClick={() => handleToggleIsdeleted(comment)}>
                                        {comment.isdeleted ? '恢復' : '刪除'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={styles.pagination}>
                    <button className={styles.paginationButton} onClick={handlePreviousPage} disabled={currentPage === 0}>
                        上一頁
                    </button>
                    <span>第 {currentPage + 1} 頁 / 共 {totalPages} 頁</span>
                    <button className={styles.paginationButton} onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                        下一頁
                    </button>
                </div>

                {selectedComment && showModal && (
                    <div className={styles.modal}>
                        <div className={styles.modalContent}>
                            <div className={styles.close} onClick={handleCloseModal}>✕</div>
                            <div className={styles.modalTitle}>留言詳情</div>
                            <p>{selectedComment.content}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CommentManagement;
