import React, { useState } from 'react';
import uspsb from '../styles/MyPostPage.module.css';
import Assistant from '../components/Assistant';
import SaveChatModal from '../components/SaveChatModal'; // 引入 SaveChatModal
import { Link, useNavigate } from 'react-router-dom'; // 引入 useNavigate 來處理導航
import AssistantSidebar from '../components/AssistantSidebar';

const AssistantPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messages, setMessages] = useState([]); // 這裡假設保存對話訊息的狀態
  const navigate = useNavigate(); // 用來導航回上一頁

  // 當用戶點擊返回時顯示彈窗
  const handleBackClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true); // 打開保存聊天記錄的彈窗
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // 關閉彈窗
    navigate(-1); // 返回上一頁
  };

  const handleDownloadChat = () => {
    // 這裡實現下載聊天記錄的邏輯
    const element = document.createElement("a");
    const file = new Blob([messages.map((msg) => `${msg.username}: ${msg.content}`).join('\n')], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "chat_history.txt";
    document.body.appendChild(element);
    element.click();
    setIsModalOpen(false); // 關閉彈窗
    navigate(-1); // 返回上一頁
  };

  return (
    <div>
      <div className={uspsb.header_container}>
        <div className={uspsb.header_icon}></div>
        <div className={uspsb.titlecontainer}>
          <Link to="/home" onClick={handleBackClick} className={uspsb.title}>
            <div className={uspsb.title}>
              ⬅返回上一頁
            </div>
          </Link>
        </div>
      </div>

      <div className={uspsb['page-container']}>
        <Assistant setMessages={setMessages} /> {/* 傳遞 setMessages 函數來保存對話記錄 */}
        <AssistantSidebar messages={messages} />
      </div>

      {/* SaveChatModal 彈窗邏輯 */}
      {isModalOpen && (
        <SaveChatModal
          onClose={handleCloseModal}
          onDownload={handleDownloadChat}
          messages={messages}
        />
      )}
    </div>
  );
};

export default AssistantPage;
