import moment from 'moment';
import imageCompression from 'browser-image-compression';

export const handlePostSubmit = async ({
  postContent,
  setPostContent,
  postImage,
  visibility,
  currentUserId,
  setIsExpanded,
  setPostImage,
  setPosts,
  setSuccessMessage,
  setReset,
  setHasLiked,
  setLikes,
  currentProfilePic,  // 直接使用最新的頭貼狀態
  currentUsername      // 直接使用最新的用戶名
}) => {
  if (!postContent.trim() && !postImage) {
    alert('請輸入內容或選擇圖片');
    return;
  }

  try {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('未找到 Token');

    const newPost = {
      content: postContent,
      image: postImage ? await convertImageToBase64(postImage) : null,
      time: moment().utc().format(),
      visibility: visibility,
      userid: currentUserId,
      profilePic: currentProfilePic,
      author: currentUsername   
    };

    const response = await fetch('https://tongbro.ddns.net:8443/api/v1/user/post', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(newPost),
    });

    if (!response.ok) {
      const errorText = await response.text();
      const errorResponse = JSON.parse(errorText);
      if (errorResponse.message === 'Token has expired') {
        alert('您的 Token 已過期，請重新登入。');
        localStorage.removeItem('token');
        window.location.href = '/login';
      } else {
        throw new Error(`Failed to create post: ${errorText}`);
      }
    } else {
      setIsExpanded(false);
      setPostImage(null);

      const result = await response.json();
      const newPostWithAuthor = { ...result, author: currentUsername, profilePic: currentProfilePic };

      setPosts(prevPosts => {
        const updatedPosts = [newPostWithAuthor, ...prevPosts];
        return updatedPosts.sort((a, b) => new Date(b.time) - new Date(a.time));
      });

      setSuccessMessage("發文成功！");

      setPostContent('');
      // 重置按讚狀態
      setHasLiked(false);  // 重置按讚狀態
      setLikes(0);         // 重置讚數
    }
  } catch (error) {
    console.error('Error:', error.message);
    alert(`發生錯誤: ${error.message}`);
  }
};

export const handleImageChange = async (e, setPostImage) => {
  const file = e.target.files[0];
  if (file && file.type.startsWith('image/')) {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(file, options);
      setPostImage(compressedFile);
    } catch (error) {
      console.error('Failed to compress image:', error);
      alert(`圖片壓縮失敗: ${error.message}`);
    }
  } else {
    setPostImage(null);
  }
};

const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
