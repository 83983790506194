import React, { useState , useEffect} from "react";
import axios from "axios";
import profile from "../styles/Profile.module.css";
import RegisterSuccessModal from "./RegisterSuccessModal";
import Select, { components } from "react-select";
import ProgressBar from './ProgressBar';

const Profile = ({ onClose, registrationData, setProgress, progress}) => {

    // 根據生日計算年齡
    const calculateAge = (birthday) => {
        const birthDate = new Date(birthday);
        const ageDiffMs = Date.now() - birthDate.getTime();
        const ageDate = new Date(ageDiffMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const [formData, setFormData] = useState({
        height: "",
        weight: "",
        workout: "",
        gender: registrationData.gender,
        age: calculateAge(registrationData.birthday) 
    });

    const [successMessage, setSuccessMessage] = useState("");

    // 使用 useEffect 讓進度條從 0% 到 50%
    useEffect(() => {
        setProgress(0); // 進入 Profile 頁面時進度條從 0% 開始
        const timeout = setTimeout(() => {
            setProgress(50); // 延遲後直接將進度設置為 50%
        }, 100); // 延遲 500 毫秒，這個時間可以根據需求調整
    
        return () => clearTimeout(timeout); // 組件卸載時清除 timeout
    }, [setProgress]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    // 表單提交的處理函數
    const handleSubmit = async (e) => {
        e.preventDefault();

        // 合併註冊資料和個人資料
        const combinedData = {
            ...registrationData, // 從之前的註冊表單中傳來的資料
            height: formData.height, // 使用者輸入的身高
            weight: formData.weight, // 使用者輸入的體重
            workout: formData.workout, // 使用者選擇的運動頻率
            age: formData.age // 計算出的年齡
        };

        try {
            // 將進度更新到 75% 表示開始提交
            setProgress(75);

            // 發送 POST 請求到後端，提交所有資料
            await axios.post("https://tongbro.ddns.net:8443/api/v1/guest/registerWithProfile", combinedData);

            // 請求成功後，將進度更新到 100%
            setProgress(100);

            // 註冊成功後，顯示成功訊息
            setSuccessMessage("註冊成功");
        } catch (err) {
            // 如果發生錯誤，將錯誤記錄到控制台
            console.error(err);
        }
    };

    // 定義運動頻率選項
    const workoutOptions = [
        {
            value: "幾乎不運動",
            label: "幾乎不運動",
            description: "坐式生活型態，如：靜臥、久坐、看電視。"
        },
        {
            value: "每週運動 1-3 天",
            label: "每週運動 1-3 天",
            description: "不太費力的基本活動，如：開車、烹飪、散步。"
        },
        {
            value: "每週運動 3-5 天",
            label: "每週運動 3-5 天",
            description: "呼吸及心跳些微加快，如：掃地、拖地、逛街、健走。"
        },
        {
            value: "每週運動 6-7 天",
            label: "每週運動 6-7 天",
            description: "呼吸及心跳快速且大量流汗，如：打球、騎腳踏車、有氧運動、游泳、登山。"
        },
        {
            value: "長時間運動或體力勞動工作",
            label: "長時間運動或體力勞動工作",
            description: "長時間耗費體力，如：長跑、運動訓練、競賽型運動。"
        },
    ];

    // 自定義 Option 組件
    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div
                    title={props.data.description} // 使用 title 屬性來顯示工具提示
                    style={{ cursor: 'pointer' }}
                >
                    {props.data.label}
                </div>
            </components.Option>
        );
    };

    return (
        <div className={profile.profile_container}>
            <ProgressBar progress={progress} />
            <button className={profile.profile_close_button} onClick={onClose}>✕</button>
            <label className={profile.profile_totaltitle}>個人資料</label>
            <label className={profile.profile_totaltitlecontent}>請填寫您的個人資料，以計算TDEE</label>
            <hr />
            <form onSubmit={handleSubmit}>
                <div className={profile.profile_form_columns}>
                    <div className={profile.profile_left_column}>
                        <label className={profile.profile_title}>身高（公分）：</label>
                        <input
                            className={profile.profile_input}
                            type="number"
                            id="height"
                            name="height"
                            value={formData.height}
                            onChange={handleChange}
                            required
                        />

                        <label className={profile.profile_title}>體重（公斤）：</label>
                        <input
                            className={profile.profile_input}
                            type="number"
                            id="weight"
                            name="weight"
                            value={formData.weight}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className={profile.profile_right_column}>
                        <label className={profile.profile_title}>運動頻率：</label>
                        <Select
                            className={`react-select__control`}
                            classNamePrefix="react-select"
                            id="workout"
                            name="workout"
                            value={workoutOptions.find(option => option.value === formData.workout)}
                            required
                            onChange={(selectedOption) => {
                                handleChange({
                                    target: {
                                        name: 'workout',
                                        value: selectedOption.value
                                    }
                                });
                            }}
                            options={workoutOptions}
                            components={{ Option }}
                            placeholder="請選擇運動頻率"
                            isSearchable={false}
                        />


                        {/* 隱藏欄位 */}
                        <input type="hidden" id="age" name="age" value={formData.age} />
                        <input type="hidden" id="gender" name="gender" value={formData.gender} />
                    </div>
                </div>

                <button type="submit" className={profile.profile_rbt}>註冊</button>

                {successMessage && <RegisterSuccessModal message={successMessage} onClose={onClose} />}
            </form>
        </div>
    );
};

export default Profile;
