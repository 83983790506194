import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../components/Login';

const LoginPage = () => {
    const navigate = useNavigate();

    // 在組件掛載時檢查是否已經有 token
    useEffect(() => {
        const token = localStorage.getItem('token'); // 從 localStorage 中獲取 token
        const role = localStorage.getItem('role'); // 获取角色信息
        if (token) {
            if (role === 'ADMIN') {
                navigate('/admin');
            } else {
                navigate('/home');
            }
        }
    }, [navigate]);

    return (
        <div>
            <Login />
        </div>
    );
}

export default LoginPage;
