export const handleCommentSubmit = async ({
    postId,
    commentInput,
    currentUserId,
    setCommentInput,
    fetchComments,
  }) => {
    const comment = commentInput[postId]?.trim();
    if (!comment) {
      alert('請輸入留言內容');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('未找到 Token，請重新登入');
  
      const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/messages/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          content: comment,
          postid: postId,
          userid: currentUserId,
        }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`留言提交失敗: ${errorText}`);
      }
  
      fetchComments(postId);
      setCommentInput(prev => ({ ...prev, [postId]: '' }));
    } catch (error) {
      console.error('Error:', error.message);
      alert(`留言發生錯誤: ${error.message}`);
    }
  };
  