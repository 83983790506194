import React, { useEffect, useState } from 'react';
import entm from '../styles/EditNutritionTableModal.module.css';

const EditNutritionTableModal = ({ isOpen, onClose, item, handleSave }) => {
    const [editData, setEditData] = useState({});

    // 使用 useEffect 監聽 isOpen 和 item 的變化
    useEffect(() => {
        if (isOpen) {
            setEditData({ ...item });
        }
    }, [isOpen, item]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        handleSave(editData);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className={entm.modalOverlay}>
            <div className={entm.modalContent}>
                <div className={entm.modalBody}>
                    <div className={entm.modalTitle}>編輯項目</div>
                    <div className={entm.Labels}>
                        <label className={entm.InputLabel}>
                            卡路里 (g):
                            <input
                                name="calories"
                                type="number"
                                value={editData.calories}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            碳水化合物 (g):
                            <input
                                name="carb"
                                type="number"
                                value={editData.carb}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            脂肪 (g):
                            <input
                                name="fat"
                                type="number"
                                value={editData.fat}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            蛋白質 (g):
                            <input
                                name="protein"
                                type="number"
                                value={editData.protein}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            鈉 (mg):
                            <input
                                name="sodium"
                                type="number"
                                value={editData.sodium}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                        <label className={entm.InputLabel}>
                            糖 (g):
                            <input
                                name="sugar"
                                type="number"
                                value={editData.sugar}
                                onChange={handleChange}
                                className={entm.Input}
                            />
                        </label>
                    </div>
                </div>
                <div className={entm.bts}>
                    <button className={entm.bt} onClick={handleSubmit}>保存</button>
                    <button className={entm.bt} onClick={onClose}>取消</button>
                </div>
            </div>
        </div>
    );
};

export default EditNutritionTableModal;
