import React, { useState, useEffect } from 'react';
import axios from 'axios';
import eatOutline from '../assets/eatOutline.png'; // 引入未吃的圖標
import eaten from '../assets/eaten.png';  // 引入已吃的圖標
import likeButtonStyles from '../styles/LikeButton.module.css'; // 引入樣式檔案

const LikeButton = ({ postId, initialLikes, currentUserId, onLikeChange }) => {
  const [likes, setLikes] = useState(initialLikes); // 初始化讚數
  const [hasLiked, setHasLiked] = useState(false); // 是否已按讚
  const [loading, setLoading] = useState(true); // 用來確保正在檢查按讚狀態時不會誤操作

  useEffect(() => {
    setLikes(initialLikes);
  }, [postId, initialLikes]);

  // 在元件加載時檢查當前用戶是否已經點過讚
  useEffect(() => {
    const checkIfLiked = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://tongbro.ddns.net:8443/api/v1/user/checklike/${postId}?userId=${currentUserId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setHasLiked(response.data.liked); // 設置是否按過讚
      } catch (error) {
        console.error('檢查用戶是否按讚時出錯:', error);
      }finally {
        setLoading(false); // 完成檢查後設置 loading 為 false
      }
    };

    if (postId) {
      setLoading(true); // 開始檢查按讚狀態
      checkIfLiked();
    }
  }, [postId, currentUserId]);

  // 處理按讚功能
  const handleLike = async () => {
    try {
      const token = localStorage.getItem('token');

      if (loading) return; // 如果正在檢查按讚狀態，則阻止操作

      if (hasLiked) {
        // 如果已按讚，則取消按讚
        await axios.post(`https://tongbro.ddns.net:8443/api/v1/user/unlike/${postId}`, null, {
          params: {
            userId: currentUserId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLikes((prevLikes) => {
          const newLikes = Math.max(0, prevLikes - 1); // 防止 likes 變成負數
          onLikeChange(postId, newLikes);
          return newLikes;
        });
        setHasLiked(false);
      } else {
        // 如果尚未按讚，則進行按讚
        await axios.post(`https://tongbro.ddns.net:8443/api/v1/user/like/${postId}`, null, {
          params: {
            userId: currentUserId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLikes((prevLikes) => {
          const newLikes = prevLikes + 1;
          onLikeChange(postId, newLikes);
          return newLikes;
        });
        setHasLiked(true);
      }
    } catch (error) {
      console.error('處理按讚/取消按讚時出錯:', error);
    }
  };

  return (
    <div className={likeButtonStyles.likeButtonContainer}>
      <img
        src={hasLiked ? eaten : eatOutline} // 根據 hasLiked 的值顯示不同的圖標
        alt={hasLiked ? '取消' : '吃'}
        className={likeButtonStyles.likeIcon} // 為圖標設置樣式
        onClick={handleLike} // 點擊圖片切換按讚/取消按讚
      />
      <span>{likes}</span> {/* 顯示讚數 */}
    </div>
  );
};

export default LikeButton;
