import React, { useEffect } from "react";
import register from "../styles/Register.module.css";

const RestPasswordSuccessModal = ({ message, onClose }) => {
    useEffect(() => {
        // 設置3秒後關閉所有彈窗
        const timer = setTimeout(() => {
            onClose();
        }, 3000);

        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className={register.modal}>
            <div className={register.modal_content}>
                {/* 成功動畫勾選圖標 */}
                <div className={register.checkmark}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M5 12l5 5L19 7" />
                    </svg>
                </div>
                {/* 成功訊息 */}
                <p>{message}</p>
            </div>
        </div>
    );
};

export default RestPasswordSuccessModal;
