import React from 'react';
import of from '../styles/OfficialFooter.module.css';

const OfficialFooter = () => {
    return (
        <div>
            <div className={of.bottomBar}>
                <p>&copy; 2024 PLTDT. All rights reserved</p>
                <p></p>
            </div>
        </div>
    );
};

export default OfficialFooter;
