import React, { useEffect } from "react";
import psm from "../styles/PostSuccessModal.module.css";
import ReactDOM from 'react-dom';

const PostSuccessModal = ({ message, onClose }) => {
    useEffect(() => {
        // 設置1秒後關閉所有彈窗
        const timer = setTimeout(() => {
            onClose();
        }, 1000);

        // 在彈窗打開時禁用滾動
        document.body.style.overflow = 'hidden';

        // 當彈窗關閉時恢復滾動
        return () => {
            clearTimeout(timer); // 清除計時器
            document.body.style.overflow = 'auto'; // 恢復滾動
        };
    }, [onClose]);

    return ReactDOM.createPortal(
        <div className={psm.overlay}>
            <div className={psm.modal}>
                <div className={psm.modal_content}>
                    {/* 成功動畫勾選圖標 */}
                    <div className={psm.checkmark}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M5 12l5 5L19 7" />
                        </svg>
                    </div>
                    {/* 成功訊息 */}
                    <p>{message}</p>
                </div>

            </div>,
        </div>,
        document.body // 將 document.body 作為容器
    );
};

export default PostSuccessModal;

