import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Line, Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import rights from "../styles/RightSidebar.module.css";
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';

// Import necessary Chart.js components
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register the components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.userid || null;
        } catch (error) {
            console.error('Token 解析失敗:', error);
            return null;
        }
    }
    return null;
};

const getToken = () => {
    return localStorage.getItem('token');
};

const RightSidebar = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [nutritionData, setNutritionData] = useState({
        protein: 0,
        carbohydrates: 0,
        fat: 0,
        calories: 0
    });
    const [calculatedCalories, setCalculatedCalories] = useState(0);
    const [tdee, setTdee] = useState(0); // 新增 TDEE 狀態
    const currentUserId = getUserIdFromToken();

    // 計算 TDEE
    const calculateTDEE = (gender, weight, height, age, workout) => {
        let bmr;

        if (gender === "男性") {
            bmr = 66 + (13.7 * weight) + (5.0 * height) - (6.8 * age);
        } else if (gender === "女性") {
            bmr = 655 + (9.6 * weight) + (1.8 * height) - (4.7 * age);
        }

        // 根據運動程度選擇運動係數
        let activityFactor = 1.2; // 默認為幾乎不運動
        if (workout === "每週運動 1-3 天") activityFactor = 1.375;
        if (workout === "每週運動 3-5 天") activityFactor = 1.55;
        if (workout === "每週運動 6-7 天") activityFactor = 1.725;
        if (workout === "長時間運動或體力勞動工作") activityFactor = 1.9;

        // 計算 TDEE
        return Math.round(bmr * activityFactor);
    };

    // useCallback 包裹 fetchUserInfo 確保它只在需要的時候重建
    const fetchUserInfo = useCallback(async () => {
        try {
            const token = getToken(); // 從 localStorage 獲取 JWT token

            const response = await fetch(`https://tongbro.ddns.net:8443/api/v1/user/userinfo/${currentUserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // 攜帶 token
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // 計算 TDEE 並設置
            const calculatedTDEE = calculateTDEE(data.gender, data.weight, data.height, data.age, data.workout);
            setTdee(calculatedTDEE);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }, [currentUserId]); // 添加 currentUserId 作為依賴項

    // 使用 useEffect 當用戶 ID 確定後獲取用戶資訊
    useEffect(() => {
        if (currentUserId) {
            fetchUserInfo();
        }
    }, [currentUserId, fetchUserInfo]); // 添加 fetchUserInfo 作為依賴項

    // 獲取營養數據
    const fetchNutritionDataByDate = useCallback(async (date) => {
        try {
            const currentUserId = getUserIdFromToken();
            if (!currentUserId) {
                console.error('User ID not found');
                return;
            }

            const formattedDate = date.toISOString().split('T')[0];
            const token = getToken();
            if (!token) {
                console.error('Token not found');
                return;
            }

            const nutrientsResponse = await axios.get(
                `https://tongbro.ddns.net:8443/api/v1/user/usernutri/${currentUserId}/date/${formattedDate}/totalnutrients`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            const totalNutrients = nutrientsResponse.data;

            if (totalNutrients && Array.isArray(totalNutrients)) {
                let totalProtein = 0;
                let totalCarb = 0;
                let totalFat = 0;
                let totalCalories = 0;

                totalNutrients.forEach(nutrient => {
                    totalProtein += nutrient.protein || 0;
                    totalCarb += nutrient.carb || 0;
                    totalFat += nutrient.fat || 0;
                    totalCalories += nutrient.calories || 0;
                });

                setNutritionData({
                    protein: totalProtein,
                    carbohydrates: totalCarb,
                    fat: totalFat,
                });

                setCalculatedCalories(totalCalories);
            } else {
                setNutritionData({ protein: 0, carbohydrates: 0, fat: 0 });
                setCalculatedCalories(0);
            }
        } catch (error) {
            console.error('Error fetching nutrition data:', error);
        }
    }, []);

    useEffect(() => {
        fetchNutritionDataByDate(selectedDate);
    }, [fetchNutritionDataByDate, selectedDate]);

    return (
        <div className={rights.container}>
            <div className="row">
                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                        setSelectedDate(date);
                        fetchNutritionDataByDate(date);
                    }}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="選擇日期"
                    className={rights.datePicker}
                    id="datePicker"
                    popperPlacement="top-end"
                />

                <div className={rights.titlecontainer} id="rightSideBarNC">
                    <div className={rights.title}>營養數據圖表</div>
                    <Line data={{
                        labels: ['蛋白質', '碳水化合物', '脂肪'],
                        datasets: [
                            {
                                label: '營養數據 (g)',
                                data: [nutritionData.protein, nutritionData.carbohydrates, nutritionData.fat],
                                backgroundColor: ['rgba(75,192,192,0.6)', 'rgba(153,102,255,0.6)', 'rgba(255,159,64,0.6)'],
                            }
                        ]
                    }} />

                    <div className={rights.text}>
                        <div>蛋白質: {nutritionData.protein} g</div>
                        <div>碳水化合物: {nutritionData.carbohydrates} g</div>
                        <div>脂肪: {nutritionData.fat} g</div>
                    </div>
                    <Link to="/nutrition" className={rights.seelink}>查看更多...</Link>
                </div>

                <div className={rights.titlecontainer} id="rightSideBarCC">
                    <div className={rights.title}>熱量數據圖表</div>
                    <Bar data={{
                        labels: ['今日攝取熱量', 'TDEE總熱量消耗'],
                        datasets: [
                            {
                                label: '熱量數據 (kcal)',
                                data: [calculatedCalories, tdee], // 使用計算的 TDEE
                                backgroundColor: ['rgba(255,99,132,0.6)', 'rgba(54,162,235,0.6)'],
                            }
                        ]
                    }} />

                    <div className={rights.text}>
                        <div>今日攝取熱量: {calculatedCalories} kcal</div>
                        {isNaN(tdee) || tdee === 0 ? (
                            <Link to="/userprofile" className={rights.text2}>請先至會員中心更新會員資料👉🏻</Link>
                        ) : (
                            <div>TDEE 總熱量消耗: {tdee} kcal</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RightSidebar;