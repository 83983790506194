import React from 'react';
import OfficialHeader from '../components/OfficialHeader';
import OfficialContent from '../components/OfficialContent';
import OfficialFooter from '../components/OfficialFooter';

const OfficialPage = () => {
    return (
        <div>
            <OfficialHeader />
            <OfficialContent />
            <OfficialFooter />
        </div>
    );
};

export default OfficialPage;
