import React, { Suspense, lazy, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons"; // Import the hamburger icon
import header from "../styles/Header.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // 引入 useNavigate

// 動態加載圖片符號的 Component
const HomeIcon = lazy(() => import("../components/icons/HomeIcon"));
const ChatIcon = lazy(() => import("../components/icons/ChatIcon"));
const ProfileIcon = lazy(() => import("../components/icons/ProfileIcon"));

const Header = ({ onIconClick, setIsAnalysisModalOpen }) => {
    const [activeIcon, setActiveIcon] = useState('home'); // 追蹤當前選中的圖標
    const [menuVisible, setMenuVisible] = useState(false); // 追蹤菜單是否可見
    const [darkMode, setDarkMode] = useState(() => {
        // 在初次渲染時從 localStorage 中獲取深色模式狀態
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === 'true'; // 確保返回布爾值
    });
    const navigate = useNavigate(); // 初始化 useNavigate

    // 在深色模式切換時，更新 body 的 class 並存儲到 localStorage
    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
        localStorage.setItem('darkMode', darkMode); // 儲存狀態到 localStorage
    }, [darkMode]);

    const handleClick = (icon) => {
        console.log(`${icon} icon clicked`);
        setActiveIcon(icon); // 更新選中的圖標
        onIconClick(icon);   // 調用父組件的 onIconClick 函數
    };

    // 切換菜單顯示狀態 
    const toggleMenu = () => {
        console.log("Hamburger icon clicked");
        setMenuVisible(!menuVisible);
    };

    // 切換深色模式
    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
    };

    const handleLogout = () => {
        console.log("正在登出...");
        localStorage.clear(); // 清空 localStorage
        const token = localStorage.getItem('token'); // 檢查 token 是否存在
        if (!token) {
            navigate("/"); // 如果沒有 token，導向首頁或登入頁
        }
    };

    // 點擊飲食分析按鈕時觸發，打開彈窗
    const handleNavigateToAnalysis = () => {
        setIsAnalysisModalOpen(true);
        setMenuVisible(false); // 點擊後隱藏漢堡菜單
    };

    return (
        <div className={header.container} id="header">
            <div className={`${header.row} row`}>
                <div className={header.logo}></div>

                {/* 漢堡菜單圖標 */}
                <div>
                    <FontAwesomeIcon
                        icon={faBars}
                        size="2x"
                        className={header.hamburgerIcon}
                        onClick={toggleMenu}
                    />
                    {/* 漢堡菜單的下拉選單 */}
                    {menuVisible && (
                        <div className={header.menu}>
                            <ul className={header.menuList}>
                                <li
                                    onClick={handleNavigateToAnalysis}
                                    className={header.menuItemeat}>
                                    飲食分析
                                </li>
                                <li className={header.menuItem}>
                                    <Link to="/userprofile" className={header.menuLink}>
                                        會員中心
                                    </Link>
                                </li>
                                <li className={header.menuItem}>
                                    <Link to="/eatdata" className={header.menuLink}>
                                        飲食紀錄
                                    </Link>
                                </li>
                                <li className={header.menuItem}>
                                    <Link to="/assistant" className={header.menuLink}>
                                        營養助手
                                    </Link>
                                </li>
                                <li className={header.menuItem} onClick={toggleDarkMode}>
                                    {darkMode ? "🌛 模式" : "🌞 模式"}
                                </li>
                                <li className={header.menuItem} onClick={handleLogout}>登出</li>
                            </ul>
                        </div>
                    )}
                </div>

                {/* 使用 Suspense 動態加載圖片符號 */}
                <div className={header.iconContainer}>
                    <Suspense fallback={<div>載入中...</div>}>
                        <HomeIcon
                            onIconClick={handleClick}
                            isSelected={activeIcon === 'home'} // 傳遞是否選中的狀態
                        />
                    </Suspense>

                    <Suspense fallback={<div>載入中...</div>}>
                        <ChatIcon
                            onIconClick={handleClick}
                            isSelected={activeIcon === 'chat'} // 傳遞是否選中的狀態
                        />
                    </Suspense>

                    <Suspense fallback={<div>載入中...</div>}>
                        <ProfileIcon
                            onIconClick={handleClick}
                            isSelected={activeIcon === 'profile'} // 傳遞是否選中的狀態
                        />
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export default Header;
