import React, { useEffect, useState, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import nit from "../styles/NutritionTable.module.css";
import EditNutritionTableModal from './EditNutritionTableModal';
import DeleteNutritionTableModal from './DeleteNutritionTableModal';
import axios from 'axios';
import { format } from 'date-fns';
import { jwtDecode } from 'jwt-decode';

const NutritionTable = ({ selectedDate, setSelectedDate }) => {
  const [nutritionData, setNutritionData] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const fetchNutritionData = useCallback(async (date) => {
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const token = localStorage.getItem('token');
      const currentUserId = getUserIdFromToken(token);

      if (!token || !currentUserId) {
        console.error("Token 或者 User ID 不存在");
        return;
      }

      const response = await axios.get(
        `https://tongbro.ddns.net:8443/api/v1/user/usernutri/${currentUserId}/date/${formattedDate}/totalnutrients`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setNutritionData(response.data);
      setCurrentPage(1); // 每次獲取數據時重置到第一頁
    } catch (error) {
      console.error('获取营养数据时出错:', error);
    }
  }, []);

  useEffect(() => {
    fetchNutritionData(selectedDate);
  }, [selectedDate, fetchNutritionData]);

  const getUserIdFromToken = (token) => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.userid || null;
      } catch (error) {
        console.error('Token 解析失敗:', error);
        return null;
      }
    }
    return null;
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `https://tongbro.ddns.net:8443/api/v1/user/nutrition/${id}/delete`,
        {},
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      setNutritionData(nutritionData.filter(item => item.dataId !== id));
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('删除营养记录时出错:', error);
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async (updatedItem) => {
    try {
      const token = localStorage.getItem('token');
      const formattedDateTime = format(new Date(updatedItem.time), "yyyy-MM-dd'T'HH:mm:ss");

      const response = await axios.put(
        `https://tongbro.ddns.net:8443/api/v1/user/nutrition/${updatedItem.dataId}/date/${formattedDateTime}`,
        updatedItem,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );

      setNutritionData(nutritionData.map(item => item.dataId === updatedItem.dataId ? response.data : item));
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('更新营养数据时出错:', error);
    }
  };

  // 計算當前頁面的數據
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = nutritionData.slice(indexOfFirstItem, indexOfLastItem);

  // 分頁按鈕的處理
  const totalPages = Math.ceil(nutritionData.length / itemsPerPage);

  return (
    <div className={nit.container}>
      <div className={nit.cardHeaderText} id="cardHeaderText">熱量日誌</div>
      <div className={nit.cardHeaderDate}>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          placeholderText="選擇日期"
          wrapperClassName="datePicker"
          className={nit.datePicker}
          id="datePicker"
          popperPlacement="top-end"
        />
      </div>
      <div className={nit.cardBody}>
        <table className={nit.table}>
          <thead id='tableHead'>
            <tr>
              <th>編號</th>
              <th>卡路里 (g)</th>
              <th className={nit.tablenoneth}>碳水化合物 (g)</th>
              <th className={nit.tablenoneth}>脂肪 (g)</th>
              <th className={nit.tablenoneth}>蛋白質 (g)</th>
              <th className={nit.tablenoneth}>鈉 (mg)</th>
              <th className={nit.tablenoneth}>糖 (g)</th>
              <th>時間</th>
              <th>功能</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <td colSpan="9" className={nit.noDataText}>目前無資料</td>
              </tr>
            ) : (
              currentItems.map((item, index) => (
                <tr id='tableBody' key={item.dataId || index}>
                  <td>{index + 1 + indexOfFirstItem}</td>
                  <td>{item.calories}</td>
                  <td className={nit.tablenonetd}>{item.carb}</td>
                  <td className={nit.tablenonetd}>{item.fat}</td>
                  <td className={nit.tablenonetd}>{item.protein}</td>
                  <td className={nit.tablenonetd}>{item.sodium}</td>
                  <td className={nit.tablenonetd}>{item.sugar}</td>
                  <td>{item.time}</td>
                  <td>
                    <div className={nit.buttonGroup}>
                      <button className={nit.edb} onClick={() => handleEdit(item)}>編輯</button>
                      <button className={nit.deb} onClick={() => { setSelectedItem(item); setIsDeleteModalOpen(true); }}>刪除</button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>

        </table>
        <div className={nit.pagination}>
          <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} className={nit.paginationButton} id="updownButton">上一頁</button>
          <span className={nit.paginationText} id="paginationText">第 {currentPage} 頁，共 {totalPages} 頁</span>
          <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} className={nit.paginationButton} id="updownButton">下一頁</button>
        </div>
      </div>

      <EditNutritionTableModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        item={selectedItem}
        handleSave={handleSaveEdit}
      />

      <DeleteNutritionTableModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={() => handleDelete(selectedItem.dataId)}
      />
    </div>
  );
};

export default NutritionTable;