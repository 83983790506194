import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';
import cropModal from '../styles/ImageCropModel.module.css'; // 確保這裡引入的路徑和名稱正確

const ImageCropModal = ({ onSave, onClose }) => {
  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState(null);

  const onCrop = (preview) => {
    setPreview(preview); // 更新預覽圖片
  };

  const onCloseCrop = () => {
    setPreview(null); // 清除預覽圖片
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setSrc(reader.result); // 設定圖片來源
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if (preview) {
      onSave(preview); // 將裁切後的圖片傳回父組件
    }
    onClose(); // 關閉模態框
  };

  return (
    <div className={cropModal.crop_modal}>
      <div className={cropModal.crop_modal_container}>
        <div className={cropModal.crop_modal_title}>裁切圖片</div>

        {!src ? (
          <div>
            {/* 隱藏 input 並用自定義按鈕觸發 */}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              id="upload-input"
              style={{ display: 'none' }} // 隱藏原始的 file input
            />
            <button
              className={cropModal.upload_button}
              onClick={() => document.getElementById('upload-input').click()}
            >
              自定義上傳圖片
            </button>
          </div>
        ) : (
          <>
            <div className={cropModal.crop_preview_container}>
              <Avatar
                width={390}
                height={295}
                onCrop={onCrop}
                onClose={onCloseCrop}
                src={src}
              />
            </div>
            {preview && (
              <div>
                <div className={cropModal.crop_preview_title}>裁切後的圖片預覽:</div>
                <img alt="Crop preview" src={preview} className={cropModal.crop_preview} />
              </div>
            )}
          </>
        )}

        <div className={cropModal.button_container}>
          {/* 根據是否有圖片裁切預覽來條件渲染保存按鈕 */}
          {preview && (
            <button className={cropModal.save_button} onClick={handleSave}>
              保存裁切
            </button>
          )}
          <button className={cropModal.cancel_button} onClick={onClose}>
            取消
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropModal;
