import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ut from "../styles/UsertableManagement.module.css"; // 自定義樣式
import EditUserModal from './EditUserModal';

const getToken = () => {
    return localStorage.getItem('token');
};

const UsertableManagement = ({ userData }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [localUserData, setLocalUserData] = useState(userData); // 這裡使用不同的變數名
    const usersPerPage = 10;

    useEffect(() => {
        // 當父組件傳遞的 userData 改變時，更新 localUserData
        setLocalUserData(userData);
    }, [userData]); // 當 userData 改變時更新 localUserData

    const totalPages = Math.ceil(localUserData.length / usersPerPage);
    const startIndex = (currentPage - 1) * usersPerPage;
    const displayedUserData = localUserData.slice(startIndex, startIndex + usersPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setIsEditModalOpen(true);
    };

    const handleSaveEdit = async (editedUser) => {
        try {
            const token = getToken();
            const response = await axios.put(
                `https://tongbro.ddns.net:8443/api/v1/admin/user/${editedUser.userid}`,
                editedUser,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            // 更新用戶資料，直接在前端修改，不需要重新從後端加載
            const updatedUserData = localUserData.map(user =>
                user.userid === editedUser.userid ? { ...user, ...response.data } : user
            );
            setLocalUserData(updatedUserData);
            setIsEditModalOpen(false);
        } catch (error) {
            console.error('保存修改時出錯:', error);
        }
    };

    const handleToggleDelete = async (user) => {
        try {
            const token = getToken();
            await axios.put(
                `https://tongbro.ddns.net:8443/api/v1/admin/user/toggleDelete/${user.userid}`,
                {},
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            const updatedUserData = localUserData.map(u =>
                u.userid === user.userid ? { ...u, isDelete: !u.isDelete } : u
            );
            setLocalUserData(updatedUserData);
        } catch (error) {
            console.error('切換用戶狀態時出錯:', error);
        }
    };

    return (
        <div className={ut.container}>
            <div className={ut.tableContainer}>
                <div className={ut.title}>使用者列表</div>
                <table className={ut.table}>
                    <thead>
                        <tr>
                            <th className={ut.id}>編號</th>
                            <th className={ut.username}>使用者名稱</th>
                            <th className={ut.email}>使用者信箱</th>
                            <th className={ut.role}>權限</th>
                            <th className={ut.status}>狀態</th>
                            <th className={ut.function}>功能</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedUserData.map((user, index) => (
                            <tr key={user.userid || index}>
                                <td>{startIndex + index + 1}</td>
                                <td className={ut.username}>{user.username}</td>
                                <td className={ut.email}>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.isDelete ? '已停用' : '啟用中'}</td> {/* 顯示狀態 */}
                                <td>
                                    <div className={ut.button}>
                                        <button
                                            className={ut.btn}
                                            onClick={() => handleEdit(user)} // 編輯按鈕
                                        >
                                            編輯
                                        </button>
                                        <button
                                            className={`${ut.btn} ${user.isDelete ? ut.btnSuccess : ut.btnDanger}`}
                                            onClick={() => handleToggleDelete(user)} // 停用/啟用按鈕
                                        >
                                            {user.isDelete ? '啟用' : '停用'}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={ut.pagination}>
                    <button className={ut.paginationButton} onClick={handlePrevPage} disabled={currentPage === 1}>上一頁</button>
                    <span className={ut.paginationText}> 第 {currentPage} 頁 / 共 {totalPages} 頁 </span>
                    <button className={ut.paginationButton} onClick={handleNextPage} disabled={currentPage === totalPages}>下一頁</button>
                </div>
            </div>

            {/* 編輯用戶的模態框 */}
            {isEditModalOpen && (
                <EditUserModal
                    user={selectedUser}
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    onSave={handleSaveEdit}
                />
            )}
        </div>
    );
};

export default UsertableManagement;
