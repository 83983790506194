import React, { useEffect } from 'react';
import cdm from '../styles/ConfirmDeleteModal.module.css';
import ReactDOM from 'react-dom';

const ConfirmDeleteModal = ({ onClose, onConfirm }) => {
  
  useEffect(() => {
    // 在彈窗打開時禁用滾動
    document.body.style.overflow = 'hidden';

    // 當彈窗關閉時恢復滾動
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={cdm.overlay}>
      <div className={cdm.modal}>
        <div className={cdm.title}>確認刪除</div>
        <div className={cdm.content}>您確定要刪除這篇文章嗎？</div>
        <div className={cdm.contentred}>刪除後無法復原 !</div>
        <div className={cdm.buttons}>
            <button className={cdm.button} onClick={onConfirm}>確認</button>
            <button className={cdm.button} onClick={onClose}>取消</button>
        </div>
      </div>
    </div>,
    document.body // 將 document.body 作為容器
  );
};

export default ConfirmDeleteModal;
