import React from 'react';
import architecture from '../assets/architecture.png';
import analysis from '../assets/analysis.png';
import cando from '../assets/cando.png';
import team from '../assets/team.png';
import chat from '../assets/chat.png';
import post from '../assets/post.png';
import aibot from '../assets/aibot.png';
import oc from '../styles/OfficialContent.module.css';

const OfficialContent = () => {
    return (
        <div>
            <div className={oc.content}>
                <h1>產品起源</h1>

                {/* <h1>減肥是一輩子的戰鬥！</h1> */}

                <img className={oc.img} src={cando} alt="Fitfeed所能做的" />

                <h1>利用Fitfeed 輕鬆記錄飲食</h1>
                <p>使用飲食分析功能，上傳圖片即可記錄飲食數據</p>
                <img className={oc.img} src={analysis} alt="飲食分析" />

                <h1>貼文及留言功能</h1>
                <p>分享每日飲食</p>
                <img className={oc.chat} src={post} alt="貼文及留言" />

                <h1>聊天室功能</h1>
                <p>打造健康飲食生活圈</p>
                <img className={oc.chat} src={chat} alt="聊天室" />

                <h1>AI營養助手</h1>
                <p>提供營養諮詢和建議</p>
                <img className={oc.chat} src={aibot} alt="AI營養助手" />

                <h1>產品架構</h1>
                <img className={oc.img} src={architecture} alt="架構圖" />

                <h1>團隊介紹</h1>

                <img className={oc.img} src={team} alt="團隊介紹" />
            </div>
        </div>
    );
};

export default OfficialContent;
