import React from 'react';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ element }) => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    if (token && role === 'ADMIN') {
        return element;
    } else {
        return <Navigate to="/" />;
    }
};

export default AdminRoute;