import React from 'react';
import styles from '../styles/ProgressBar.module.css';

const ProgressBar = ({ progress }) => {
    return (
        <div className={styles['progress-bar-container']}>
            <div className={styles['progress-bar']}>
                <div
                    className={styles['progress-bar-fill']}
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
            <div className={styles['progress-label']}>
                {progress === 100 ? '註冊完成' : `註冊進度 ${progress}%`}
            </div>
        </div>
    );
};

export default ProgressBar;
