import React, { useEffect } from 'react';
import scm from '../styles/SaveChatModal.module.css'; // 您可以根據需要修改樣式檔案的名稱

const SaveChatModal = ({ onClose, onDownload, messages }) => {

  useEffect(() => {
    // 在彈窗打開時禁用滾動
    document.body.style.overflow = 'hidden';

    // 當彈窗關閉時恢復滾動
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const hasMessages = messages && messages.length > 0;

  return (
    <div className={scm.overlay}>
      <div className={scm.modal}>
        <div className={scm.title}>下載與營養助手的對話</div>
        <div className={scm.content}>
          {hasMessages ? (
            // 顯示對話紀錄
            <textarea
              readOnly
              className={scm.chatHistory}
              value={messages.map((msg) => `${msg.username}: ${msg.content}`).join('\n')}
            />
          ) : (
            // 當沒有對話紀錄時顯示的訊息
            <p>目前沒有聊天記錄。</p>
          )}
        </div>
        <div className={scm.buttons}>
          {hasMessages && (
            <button className={scm.button} onClick={onDownload}>下載</button>
          )}
          <button className={scm.button} onClick={onClose}>關閉</button>
        </div>
      </div>
    </div>
  );
};

export default SaveChatModal;
